import React, {useState, useContext} from "react";
import FormFieldMicro from "./FormFieldMicro";
import UserContext from "../UserContext";

const MiniNotes = props => {
    const [text, setText] = useState('');
    const userContext = useContext(UserContext);

    const handleChange = (event) => {
        event.preventDefault();
        if(event.target.name === "text") {
            setText(event.target.value);
        }
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const addNote = (event) => {
        event.preventDefault();
        let newNotes = JSON.parse(JSON.stringify(props.notes));
        newNotes.unshift({
            author: {
                first: userContext.user.first,
                last: userContext.user.last,
                email: userContext.user.email
            },
            text: text,
            createdAt: new Date(),
            flagged: false
        })
        props.setNotes(newNotes);
        setText('');
    }

    const toggleFlagAtIndex = (i) => {
        let newNotes = JSON.parse(JSON.stringify(props.notes));
        newNotes[i].flagged = !newNotes[i].flagged;
        props.setNotes(newNotes);
    }

    const removeNoteAtIndex = (i) => {
        props.setNotes(props.notes.filter((_, index) => index !== i));
    }

    const noteDisplay = (note, i) => {
        let date = null;
        if(note.createdAt) {
            date = new Date(note.createdAt);
        }
        return <div key={i} className="section-outline w-100 text-start mt-2">
            <strong>{note.author.first} {note.author.last}</strong>&nbsp;
            <button className="btn p-0 ps-1" onClick={(e) => {e.preventDefault(); toggleFlagAtIndex(i)}}>
                <i className={`fas fa-flag ${note.flagged ? 'text-danger' : 'text-muted'}`}/>
            </button>
            <button className="btn p-0 ps-1" onClick={(e) => {e.preventDefault(); removeNoteAtIndex(i)}}>
                <i className={'fas fa-trash text-muted'}/>
            </button>
            <br/>
            <i className="text-muted">{note.createdAt ? date.toLocaleString() : "(Unsaved)"}</i>
            <p>{note.text}</p>
        </div>
    }

    return (
        <div>
            <FormFieldMicro
                type="textarea"
                name="text"
                label="Notes"
                value={text}
                handleChange={handleChange}
                onBlur={trimOnBlur}
                disabled={props.disabled}
                resize="vertical"
            />
            <button className="btn btn-primary" disabled={text.trim() === ""} onClick={addNote}>
                <i className="fas fa-plus"/>&nbsp;Post Note
            </button>
            <div className="overflow-auto" style={{maxHeight: 850}}>
                {props.notes.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).map((note, i) => noteDisplay(note, i))}
            </div>
        </div>
    );
}

export default MiniNotes;