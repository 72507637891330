import React, {useState, useContext, useEffect} from 'react';
import {BannerLog} from '../BannerLogContext';
import Modal, {choiceCancel, choiceDelete} from '../Modal';
import ModalContext from '../ModalContext';
import LoadingSpinner from '../LoadingSpinner';
import ProjectForm from './ProjectForm';
import chimera from '../../chimera';

const ProjectFormModal = ({project, parentBanners, parentSetSavedProject, onClose}) => {
    const [_project, setProject] = useState(project);
    const [modified, setModified] = useState(false);
    const modaling = useContext(ModalContext);

    const handleSaveAndClose = (resolve, reject) => {
        const dismiss = (event) => {
            event.preventDefault();
            modaling.backtrack();
        }
        modaling.setModal(<Modal choices={[]} dismiss={dismiss}>
            <LoadingSpinner size={75} label="Saving..."/>
        </Modal>)
        const isPost = _project._id ? false : true;
        chimera.callAPI(undefined, `/api/projects${!isPost ? `/${_project._id}` : ''}`, isPost ? 'POST' : 'PUT', _project)
        .then(savedProject => {
            if(parentSetSavedProject) {
                parentSetSavedProject(savedProject);
            }
            resolve();
        })
        .catch(err => reject(err));
    }

    const saveAndCloseResolve = () => {
        if(onClose) onClose();
        modaling.setModal(null);
    }

    const saveAndCloseReject = (err) => {
        console.error(err);
        alert(`ERROR: Failed to save the Project. Details: ${err.toString()}`);
        modaling.backtrack();
    }

    const choices = [
        choiceCancel({modalContext: modaling, onClose}, modified, <>
            <h3>You have unsaved changes</h3>
            <p>Are you sure you want to close this form? Your changes will not be saved.</p>
        </>, 'Close', {func: handleSaveAndClose, resolve: saveAndCloseResolve, reject: saveAndCloseReject})
    ]

    if(project && project._id) {
        choices.push(choiceDelete({modalContext: modaling}, () => {
            modaling.setModal(<Modal choices={[]} dismiss={(e) => {e.preventDefault(); modaling.setModal(null)}}>
                <LoadingSpinner size={75}/>
            </Modal>);
            chimera.callAPI(undefined, `/api/projects/${project._id}`, 'DELETE')
            .then(_ => {
                if(parentBanners) {
                    parentBanners.addBanner('info', `Project #${project.number} has been deleted.`, 'Success');
                }
            })
            .catch(err => {
                console.error(err);
                if(parentBanners) {
                    parentBanners.addBanner('danger', 'Failed to delete Project', 'Error');
                }
                else {
                    alert('Error: Failed to delete Project');
                }
            })
            .finally(() => {
                if(onClose) onClose();
                modaling.setModal(null);
            })
        }, {label: 'Delete Permanently'}))
    }

    return (
        <Modal choices={choices} dismiss={choices[0].func}>
            <BannerLog>
                <ProjectForm project={project} parentSetModified={setModified} parentSetProject={setProject} parentSetSavedProject={parentSetSavedProject}/>
            </BannerLog>
        </Modal>
    )
}

export default ProjectFormModal;