import React, {useState, useContext, useEffect} from 'react';
import chimera from '../../chimera';
import FormFieldMicro from '../FormFieldMicro';
import BannerContext, {BannerLog} from '../BannerLogContext';
import Modal, {choiceCancel, choiceDelete} from '../Modal';
import LoadingSpinner from '../LoadingSpinner';
import ModalContext from '../ModalContext';
import MiniNotes from '../MiniNotes';
import AutocompleteNew from '../AutocompleteNew';
import FileUpload from '../FileUpload';
import UserContext from '../../UserContext';

/**
 *  @param props.workingOrder the object being edited. If left undefined or null, POST requests will be used on save instead of PUT.
 * */
const SubcontractorOrderFormBody = props => {
    const banners = useContext(BannerContext);
    const modaling = useContext(ModalContext);
    const userContext = useContext(UserContext);

    const checkLocked = () => {
        if(userContext.permissions.admin.write && !props.isSaving) return false;
        if(props.isSaving) return true;
    }

    const locked = checkLocked();

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }
    
    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        props.setModified(true);
        const name = event.target.name;
        const value = event.target.type !== "checkbox" ? event.target.value : event.target.checked;
        let newWorkingOrder = JSON.parse(JSON.stringify(props.workingOrder));
        
        if(event.target.type === "number") {
            chimera.setAttr(newWorkingOrder, name, parseFloat(value));
        }
        else if(name.split('.')[name.split('.').length-1].toLowerCase().includes("phone")) {
            chimera.setAttr(newWorkingOrder, name, value.replace(/\D/g, ''));
        }
        else {
            chimera.setAttr(newWorkingOrder, name, value);
        }

        props.setWorkingOrder(newWorkingOrder);
    }

    const handleSaveError = (err) => {
        console.error(err);
        if(err.details && err.details.name === "ValidationError") {
            for(const key in err.details.errors) {
                banners.addBanner('danger', err.details.errors[key].message, 'Validation Error');
            }
        }
        else if(err.name !== 'AbortError') {
            banners.addBanner('danger', 'Failed to save order', 'Error');
        }
    }

    const handleSave = (event, order) => {
        if(event) event.preventDefault();
        const resolve = (savedOrder) => {
            if(props.saveMode === 'PUT') {
                banners.addBanner('info', `Changes saved successfully.`, 'Saved');
            }
            else { // saveMode === 'POST'
                banners.addBanner('info', `The Subcontractor Order${savedOrder.customer.displayName ? ` for ${savedOrder.customer.displayName}` : ""} was created successfully.`, 'Success');
                props.setSaveMode('PUT');
            }
            props.setWorkingOrder(savedOrder);
            props.setSavedOrder(savedOrder);
        }
        props.handleSave(order)
        .then(response => resolve(response))
        .catch(err => handleSaveError(err));
    }

    const setNotes = (newNotes) => {
        props.setModified(true);
        const newWorkingOrder = JSON.parse(JSON.stringify(props.workingOrder));
        newWorkingOrder.notes = newNotes;
        props.setWorkingOrder(newWorkingOrder);
    }

    const customerSuggestionChosenCallback = (customer) => {
        const oldValues = props.workingOrder.customer;
        const newValues = {
            displayName: customer ? customer.displayName : '',
            qbId: customer ? customer.integrationIds.quickbooks : '',
            chimeraId: customer ? customer._id : ''
        }
        if(!chimera.deepEqual(oldValues, newValues)) {
            const newOrder = JSON.parse(JSON.stringify(props.workingOrder));
            newOrder.customer = newValues;
            props.setWorkingOrder(newOrder);
            props.setModified(true);
        }
    }

    const vendorSuggestionChosenCallback = (vendor) => {
        const oldValues = props.workingOrder.subcontractor;
        const newValues = {
            name: vendor ? vendor.DisplayName : '',
            ref: vendor ? vendor.Id : ''
        }
        if(!chimera.deepEqual(oldValues, newValues)) {
            const newOrder = JSON.parse(JSON.stringify(props.workingOrder));
            newOrder.subcontractor = newValues
            props.setWorkingOrder(newOrder);
            props.setModified(true);
        }
    }

    const addAttachment = (file, contents) => {
        console.log({file, contents});

        chimera.callAPI(undefined, '/api/file', 'POST', {
            filename: file.name,
            type: file.type,
            size: file.size,
            content: contents,
            encoding: 'base64'
        })
        .then(savedFile => {
            const newOrder = JSON.parse(JSON.stringify(props.workingOrder));
            let newAttachment = {
                filename: savedFile.filename,
                type: savedFile.type,
                size: savedFile.size,
                id: savedFile._id
            }
            if(newOrder.attachments === undefined) {
                newOrder.attachments = [newAttachment]
            }
            else {
                newOrder.attachments.push(newAttachment);
            }
            handleSave({preventDefault: () => {}}, newOrder);
        })
        .catch(err => {
            console.error(err);
            banners.addBanner('danger', 'Failed to upload attachment', 'Error');
        })
        .finally(() => {
            modaling.backtrack();
        })
    }

    const openFileUploadModal = (event) => {
        event.preventDefault();
        const choices = [
            choiceCancel({modalContext: modaling, backtrack: true}),
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <FileUpload callback={addAttachment}/>
        </Modal>
        modaling.setModal(modal);
    }

    const sizeAbbreviation = (sizeInBytes) => {
        if(sizeInBytes >= 1000000) {
            return `${(sizeInBytes / 1000000).toFixed(1)} MB`;
        }
        else if(sizeInBytes >= 1000) {
            return `${(sizeInBytes / 1000).toFixed(1)} KB`;
        }
        else {
            return `${(sizeInBytes).toFixed(1)} B`;
        }
    }

    const downloadAttachment = (attachment) => {
        const modal = <Modal choices={[]} dismiss={(e) => {e.preventDefault(); modaling.backtrack();}}>
            <LoadingSpinner size={75} label="Downloading..."/>
        </Modal>
        modaling.setModal(modal);

        chimera.callAPI(undefined, `/api/file/${attachment.id}`)
        .then(file => {
            fetch(`data:${file.type};base64,${file.content}`)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = file.filename;
                link.click();
            })
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to download file', 'Error');
            })
        })
        .catch(err => {
            console.error(err);
            banners.addBanner('danger', 'Failed to download file', 'Error');
        })
        .finally(() => {
            modaling.backtrack();
        })
    }

    const deleteAttachment = (attachment) => {

        const choices = [
            choiceCancel({modalContext: modaling, backtrack: true}),
            choiceDelete(
                {modalContext: modaling},
                () => {
                    const modal = <Modal choices={[]} dismiss={(e) => {e.preventDefault(); modaling.backtrack(); modaling.backtrack();}}>
                        <LoadingSpinner size={75} label="Deleting..."/>
                    </Modal>
                    modaling.setModal(modal);
            
                    chimera.callAPI(undefined, `/api/file/${attachment.id}`, 'DELETE')
                    .then(_ => {
                        const newOrder = JSON.parse(JSON.stringify(props.workingOrder));
                        newOrder.attachments = newOrder.attachments.filter(a => a.id !== attachment.id);
                        handleSave({preventDefault: () => {}}, newOrder);
                    })
                    .catch(err => {
                        console.error(err);
                        banners.addBanner('danger', 'Failed to delete file', 'Error');
                    })
                    .finally(() => {
                        modaling.backtrack();
                        modaling.backtrack();
                    })
                },
                {noConfirm: true}
            )
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Are you sure?</h3>
            <p>Are you sure you want to delete the attached file? This operation cannot be undone. Filename: {attachment.filename}</p>
        </Modal>
        modaling.setModal(modal);
    }

    const openInvoiceInNewTab = (invoiceId) => {
        window.open(`https://app.qbo.intuit.com/app/invoice?txnId=${invoiceId}`, '_blank');
    }

    const calculateSubTotal = () => {
        let total = 0;
        for(const item of props.workingOrder.items) {
            if(item.subCost === '') continue;
            let costAsStr = item.subCost;
            if(costAsStr.startsWith('$')) {
                costAsStr = costAsStr.substring(1);
            }
            total += parseFloat(costAsStr);
        }
        try {
            let totalStr = chimera.dollarStr(total);
            if(totalStr === NaN) {
                throw new Error("dollarStr gave NaN");
            }
            else {
                return totalStr;
            }
        }
        catch(err) {
            return <span className="text-danger">Error: Check costs below</span>;
        }
    }

    const calculateCustomerTotal = () => {
        let total = 0;
        for(const item of props.workingOrder.items) {
            if(item.customerCost === '') continue;
            let costAsStr = item.customerCost;
            if(costAsStr.startsWith('$')) {
                costAsStr = costAsStr.substring(1);
            }
            total += parseFloat(costAsStr);
        }
        try {
            let totalStr = chimera.dollarStr(total);
            if(totalStr === NaN) {
                throw new Error("dollarStr gave NaN");
            }
            else {
                return totalStr;
            }
        }
        catch(err) {
            return <span className="text-danger">Error: Check costs below</span>;
        }
    }

    const addItem = (e) => {
        e.preventDefault();
        const newWorkingOrder = JSON.parse(JSON.stringify(props.workingOrder));
        newWorkingOrder.items = [...newWorkingOrder.items, {description: '', subCost: '', customerCost: ''}];
        props.setWorkingOrder(newWorkingOrder);
    }

    const removeItemAtIndex = (index) => {
        const newWorkingOrder = JSON.parse(JSON.stringify(props.workingOrder));
        newWorkingOrder.items = newWorkingOrder.items.filter((_, i) => i !== index);
        props.setWorkingOrder(newWorkingOrder);
    }

    return(
        <div className="row">
            <div className="col-9 border-end">
                <h3>Subcontractor Work Order</h3>
                <div className="row row-cols-1 row-cols-md-4 g-4">
                    <FormFieldMicro
                        type="text"
                        name="ticketNumber"
                        label="Ticket #"
                        value={props.workingOrder.ticketNumber}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={locked}
                        size={6}
                        required
                        fit
                    />
                    {/** TODO: Auto-assign qbInvoice.id in save handler */}
                    <FormFieldMicro
                        type="text"
                        name="qbInvoice.number"
                        label="QB Invoice #"
                        value={props.workingOrder.qbInvoice.number}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={locked}
                        size={6}
                        fit
                    >
                        {props.workingOrder.qbInvoice.id ? 
                            <>
                            <div className="tooltip-container border-0">
                                <span className="tooltip-text-bottom">
                                    Open QB Invoice in New Tab
                                </span>
                                <button className="btn btn-secondary btn-sm mt-1" onClick={(e) => {e.preventDefault(); openInvoiceInNewTab(props.workingOrder.qbInvoice.id)}}>
                                    <i className="fas fa-up-right-from-square"/>
                                </button>
                            </div>
                            &nbsp;Status: {props.workingOrder.qbInvoice.status !== "NOT SET" ? props.workingOrder.qbInvoice.status : "(Check QB)"}
                            </>
                        :null}
                    </FormFieldMicro>
                    <FormFieldMicro
                        type="text"
                        name="orderNumber"
                        label="Order #"
                        value={props.workingOrder.orderNumber}
                        disabled
                        size={6}
                        fit
                    />
                    <FormFieldMicro
                        type="date"
                        name="dateRequired"
                        label="Date Required"
                        value={props.workingOrder.dateRequired}
                        handleChange={handleChange}
                        disabled={locked}
                        fit
                    />
                </div>
                <div className="row row-cols-1 row-cols-md-2 g-2">
                    <AutocompleteNew
                        label="Customer"
                        value={props.workingOrder.customer.chimeraId}
                        objects={props.customers}
                        labelRule={(c) => c.displayName}
                        valueRule={(c) => c._id}
                        objectChosenCallback={customerSuggestionChosenCallback}
                        strictMode
                        required
                        disabled={locked}
                        isLoading={props.customers === null}
                    />
                    <AutocompleteNew
                        label="Subcontractor"
                        value={props.workingOrder.subcontractor.ref}
                        objects={props.vendors}
                        labelRule={(v) => v.DisplayName}
                        valueRule={(v) => v.Id}
                        objectChosenCallback={vendorSuggestionChosenCallback}
                        strictMode
                        required
                        disabled={locked}
                        isLoading={props.vendors === null}
                    />
                </div>
                <div className="row row-cols-1 row-cols-md-2 g-2">
                    <FormFieldMicro
                        type="select"
                        name="customerQuoted"
                        label="Customer Quoted?"
                        value={props.workingOrder.customerQuoted}
                        handleChange={handleChange}
                        options={[
                            {id: true, value: "Yes"}, // `id` is the value and `value` is the label. this is bad, please fix later
                            {id: false, value: "No"},
                        ]}
                        excludeNoneSelected
                        disabled={locked}
                        fit
                    />
                    <FormFieldMicro
                        type="select"
                        name="billable"
                        label="Billable?"
                        value={props.workingOrder.billable}
                        handleChange={handleChange}
                        options={[
                            {id: true, value: "Yes"}, // `id` is the value and `value` is the label. this is bad, please fix later
                            {id: false, value: "No"},
                        ]}
                        excludeNoneSelected
                        disabled={locked}
                        fit
                    />
                </div>
                <div className="row row-cols-1 row-cols-md-3 g-3">
                    <FormFieldMicro
                        type="text"
                        name="subInvoice.number"
                        label="Sub Invoice #"
                        value={props.workingOrder.subInvoice.number}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={locked}
                        size={6}
                        fit
                    />
                    <span><strong>Total Sub Cost:</strong>&nbsp;{calculateSubTotal()}</span>
                    <span><strong>Total Customer Cost:</strong>&nbsp;{calculateCustomerTotal()}</span>
                </div>
                <hr/>
                {/** TODO: items[] goes here */}
                <h4 className="text-start">Items</h4>
                {props.workingOrder.items.map((item, i) => <div key={i} className={`section-outline${i > 0 ? ' mt-2' : ''}`}>
                    <div className="row row-cols-1">
                        <FormFieldMicro
                            type="textarea"
                            name={`items[${i}].description`}
                            label="Description"
                            value={item.description}
                            handleChange={handleChange}
                            onBlur={trimOnBlur}
                            disabled={locked}
                            resize="vertical"
                        />
                    </div>
                    <div className="row row-cols-1 row-cols-md-3 g-3">
                        <div className="col">
                            <FormFieldMicro
                                type="text"
                                name={`items[${i}].subCost`}
                                label="Sub Cost"
                                value={item.subCost}
                                handleChange={handleChange}
                                onBlur={trimOnBlur}
                                disabled={locked}
                                size={10}
                                placeholder={"$0.00"}
                                pattern={"\\$?[\\d\\,]+\\.\\d\\d"}
                                fit
                            />
                        </div>
                        <div className="col">
                            <FormFieldMicro
                                type="text"
                                name={`items[${i}].customerCost`}
                                label="Customer Cost"
                                value={item.customerCost}
                                handleChange={handleChange}
                                onBlur={trimOnBlur}
                                disabled={locked}
                                size={10}
                                placeholder={"$0.00"}
                                pattern={"\\$?[\\d\\,]+\\.\\d\\d"}
                                fit
                            />
                        </div>
                        {props.workingOrder.items.length > 1 ?
                            <div className="col">
                                <button className="btn btn-sm btn-danger float-end w-fit h-fit" onClick={(e) => {e.preventDefault(); removeItemAtIndex(i)}}>
                                    <i className="fas fa-times"/>
                                </button>
                            </div>
                        :null}
                    </div>
                </div>)}
                <button className="btn btn-success mt-2" onClick={addItem}>
                    <i className="fas fa-plus"/>&nbsp;Add Item
                </button>
                <hr/>
                <div className="section-outline">
                    <div className="row">
                        <div className="col">
                            <h5 className="text-start">Attachments</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-start">
                            {!props.workingOrder.attachments || props.workingOrder.attachments.length === 0 ? <span className="text-muted">There are no attachments yet</span> : <ol className="text-start">
                                {props.workingOrder.attachments.map((attachment, i) => <li key={i}>
                                    <span>
                                        <a href="#" onClick={(e) => {e.preventDefault(); downloadAttachment(attachment)}}>
                                            {attachment.filename}
                                        </a>
                                        &nbsp;
                                        ({sizeAbbreviation(attachment.size)})
                                        &nbsp;
                                        <button className="btn btn-sm btn-danger" onClick={(e) => {e.preventDefault(); deleteAttachment(attachment)}} disabled={props.isSaving}>
                                            <i className="fas fa-times"/>
                                        </button>
                                    </span>
                                </li>)}
                            </ol>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-3">
                {/** Sidebar */}
                <FormFieldMicro
                    type="select"
                    name="status"
                    label="Status"
                    value={props.workingOrder.status}
                    handleChange={handleChange}
                    options={[
                        {id: "New", value: "New"},
                        {id: "Scheduled", value: "Scheduled"},
                        {id: "Invoiced", value: "Invoiced"},
                        {id: "Ready to Pay Sub", value: "Ready to Pay Sub"},
                        {id: "Ready to Bill Customer", value: "Ready to Bill Customer"},
                        {id: "Completed", value: "Completed"},
                    ]}
                    fullWidth
                    excludeNoneSelected
                />
                <button className="btn btn-primary w-100 mb-1" onClick={handleSave} disabled={props.saveDisabled}>
                    <i className={props.isSaving ? "fas fa-spinner" : "fas fa-floppy-disk"}/>&nbsp;{props.isSaving ? "Saving..." : "Save"}
                </button>
                <button className="btn btn-primary w-100 mb-1" onClick={openFileUploadModal} disabled={props.isSaving}>
                    <i className="fas fa-file"/>&nbsp;Upload Attachment
                </button>
                {props.workingOrder.author ? 
                    <p className="text-muted">Order created by {props.workingOrder.blame.createdBy.first} {props.workingOrder.blame.createdBy.last} ({props.workingOrder.blame.createdBy.email}) at {(new Date(props.workingOrder.createdAt)).toLocaleString()}</p>
                : null}
                <MiniNotes notes={props.workingOrder.notes} setNotes={setNotes} disabled={props.isSaving}/>
            </div>
        </div>
    )
}

const SubcontractorOrderFormModal = props => {
    const DEFAULT_ORDER = {
        customer: {
            displayName: '',
            chimeraId: '',
            qbId: ''
        },
        subcontractor: {
            name: '',
            ref: '',
        },
        ticketNumber: '',
        qbInvoice: {
            number: '',
            id: '',
            status: 'NOT SET'
        },
        subInvoice: {
            number: ''
        },
        items: [
            {
                description: '',
                subCost: '',
                customerCost: ''
            }
        ],
        status: 'New',
        customerQuoted: false,
        billable: false,
        dateRequired: '',
        notes: [],
        attachments: []
    }
    const [workingOrder, setWorkingOrder] = useState(props.orderId ? null : DEFAULT_ORDER);
    const [savedOrder, setSavedOrder] = useState(null);
    const [modified, setModified] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [saveMode, setSaveMode] = useState(props.orderId ? 'PUT' : 'POST');
    const [customers, setCustomers] = useState(null);
    const [vendors, setVendors] = useState(null);
    const modaling = useContext(ModalContext);

    useEffect(() => {
        if(props.orderId) {
            chimera.callAPI(undefined, `/api/subcontractororders/${props.orderId}`)
            .then(order => {
                setWorkingOrder(order);
                setSavedOrder(order);
            })
        }
    }, [props.orderId]);

    useEffect(() => {
        if(customers === null) {
            chimera.callAPI(undefined, '/api/customers')
            .then(customers => setCustomers(customers))
            .catch(err => {
                console.error(err);
                alert("Failed to read customers. Cannot open form.");
            })
        }
    }, [customers]);

    useEffect(() => {
        if(vendors === null) {
            chimera.callQuickBooksAPI(undefined, '/api/qb/vendor')
            .then(newVendors => setVendors(newVendors))
            .catch(err => {
                console.error(err);
                alert("ERROR: An unhandled exception has occurred when trying to read QuickBooks Vendors. Please refresh and try again.");
            })
        }
    }, [vendors]);

    const saveDisabled = () => {
        if(isSaving) return true;
        if(saveMode === 'PUT') {
            return chimera.deepEqual(workingOrder, savedOrder);
        }
        else {
            return !modified;
        }
    }

    const handleSave = (order) => {
        setIsSaving(true);
        let body = JSON.parse(JSON.stringify(order ? order : workingOrder));
        return new Promise(async(resolve, reject) => {
            if(body.qbInvoice.number && !body.qbInvoice.id || (savedOrder && body.qbInvoice.number !== savedOrder.qbInvoice.number)) {
                try {
                    const invoice = await chimera.callQuickBooksAPI(undefined, `/api/qb/invoice/${body.qbInvoice.number}`);
                    body.qbInvoice.id = invoice.Id;
                    body.qbInvoice.status = invoice.Balance === 0 ? "Paid" : "Unpaid";
                }
                catch(err) {
                    reject(err);
                }
            }

            chimera.callAPI(undefined, `/api/subcontractororders${saveMode === "PUT" ? `/${order ? order._id : workingOrder._id}` : ''}`, saveMode, body)
            .then(savedOrder => {
                setIsSaving(false); // this line present in both clauses BEFORE the resolve/reject is called prevents memory leaks if resolve/reject unmounts the component
                resolve(savedOrder);
            })
            .catch(err => {
                setIsSaving(false);
                reject(err);
            });
        })
    }

    const saveAndCloseFunc = (resolve, reject) => {
        const dismiss = (event) => {
            event.preventDefault();
            modaling.backtrack();
        }
        modaling.setModal(<Modal choices={[]} dismiss={dismiss}>
            <LoadingSpinner size={75}/>
        </Modal>)

        handleSave()
        .then(result => resolve(result))
        .catch(err => reject(err));
    }

    const saveAndCloseResolve = (savedOrder) => {
        setWorkingOrder(savedOrder);
        setSavedOrder(savedOrder);
        setSaveMode('PUT');
        modaling.setModal(null);
    }

    const saveAndCloseReject = (err) => {
        console.error(err);
        alert("ERROR: Failed to save changes");
        modaling.backtrack();
    }

    const choices = [
        choiceCancel(props, !saveDisabled(), <>
            <h3>You have unsaved changes</h3>
            <p>Are you sure you want to close this form? Your changes will not be saved.</p>
        </>, "Close", {func: saveAndCloseFunc, resolve: saveAndCloseResolve, reject: saveAndCloseReject})
    ]

    if(saveMode === 'PUT') {
        choices.push(choiceDelete(props, () => {
            modaling.setModal(<Modal choices={[]} dismiss={(e) => {e.preventDefault(); modaling.setModal(null)}}>
                <LoadingSpinner size={75}/>
            </Modal>);
            chimera.callAPI(undefined, `/api/subcontractororders/${savedOrder._id}`, 'DELETE')
            .then(_ => {
                props.banners.addBanner('info', 'The order has been deleted.', 'Success');
            })
            .catch(err => {
                console.error(err);
                //alert("ERROR: Failed to delete the order");
                props.banners.addBanner('danger', 'Failed to delete the order.', 'Error');
            })
            .finally(() => {
                props.onClose();
                modaling.setModal(null);
            })
        }, {label: "Delete Permanently"}))
    }

    return (
        <Modal choices={choices} dismiss={choices[0].func}>
            <BannerLog>
                {workingOrder && vendors && customers ? 
                <SubcontractorOrderFormBody {...props} 
                    workingOrder={workingOrder} setWorkingOrder={setWorkingOrder} 
                    savedOrder={savedOrder} setSavedOrder={setSavedOrder}
                    modifed={modified} setModified={setModified}
                    isSaving={isSaving} setIsSaving={setIsSaving}
                    saveMode={saveMode} setSaveMode={setSaveMode}
                    saveDisabled={saveDisabled()}
                    handleSave={handleSave}
                    customers={customers}
                    vendors={vendors}
                />
                : <LoadingSpinner size={50}/>}
            </BannerLog>
        </Modal>
    )
}

export default SubcontractorOrderFormModal;