import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';

import LoadingSpinner from '../LoadingSpinner';
import ModalContext from '../ModalContext';
import ObjectTable from '../ObjectTable';
import chimera from '../../chimera';
import BannerContext, { BannerLog } from '../BannerLogContext';
import Modal, {choiceCancel, choiceDelete} from '../Modal';
import ExportModal from '../ExportModal';
import FormFieldMicro from '../FormFieldMicro';
import Tooltip from '../Tooltip';

const DEFAULT_USER = {
    first: '',
    last: '',
    email: '',
    groups: ['Engineer']
}

function getGroupsPreview(user) {
    const str = user.groups.join(', ');
    return str.substring(0, 20) + (str.length > 20 ? '...' : '');
}

const UserFormModalBody = props => {
    const banners = useContext(BannerContext);
    const modaling = useContext(ModalContext);

    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        props.setModified(true);
        const name = event.target.name;
        const value = event.target.type !== "checkbox" ? event.target.value : event.target.checked;

        let newWorkingUser = JSON.parse(JSON.stringify(props.workingUser));
        
        if(name.startsWith('group')) {
            const groupName = name.substring(5);
            if(value && !newWorkingUser.groups.includes(groupName)) {
                newWorkingUser.groups.push(groupName);
            }
            else {
                newWorkingUser.groups = newWorkingUser.groups.filter(group => group !== groupName);
            }
        }
        else {
            chimera.setAttr(newWorkingUser, name, value);
        }

        props.setWorkingUser(newWorkingUser);
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const handleSaveError = (err) => {
        console.error(err);
        if(err.status === 404) {
            if(err.details.message) {
                banners.addBanner('danger', err.details.message, '404 Error');
            }
            else {
                console.error(err.details);
                banners.addBanner('danger', '', '404 Error');
            }
        }
        else if(err.status === 403) {
            banners.addBanner('danger', 'You do not have permission to do that.', 'Forbidden');
        }
        else if(err.details && err.details.name === "ValidationError") {
            for(const key in err.details.errors) {
                banners.addBanner('danger', err.details.errors[key].message, 'Validation Error');
            }
        }
        else if(err.name !== 'AbortError') {
            banners.addBanner('danger', 'Failed to save user', 'Error');
        }
        props.setIsSaving(false);
    }

    const handleSave = (event) => {
        if(event) event.preventDefault();
        const resolve = (savedUser) => {
            if(props.saveMode === 'PUT') {
                if(props.parentBanners) props.parentBanners.addBanner('info', `Changes saved successfully.`, 'Saved');
            }
            else { // saveMode === 'POST'
                if(props.parentBanners) props.parentBanners.addBanner('info', `User "${savedUser.first} ${savedUser.last}" was created successfully.`, 'Success');
                props.setSaveMode('PUT');
            }
            props.setWorkingUser(savedUser);
            props.setSavedUser(savedUser);
            props.setIsSaving(false);
            props.onClose();
            modaling.setModal(null);
        }
        props.handleSave()
        .then(response => resolve(response))
        .catch(err => handleSaveError(err));
    }

    return(
        <div>
            <div className="row">
                <div className="col">
                    <FormFieldMicro
                        type="text"
                        name="first"
                        label="First Name"
                        value={props.workingUser.first}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={props.isSaving}
                        required
                    />
                </div>
                <div className="col">
                    <FormFieldMicro
                        type="text"
                        name="last"
                        label="Last Name"
                        value={props.workingUser.last}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={props.isSaving}
                        required
                    />
                </div>
                <div className="col">
                    <FormFieldMicro
                        type="text"
                        name="email"
                        label="Email"
                        value={props.workingUser.email}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={props.isSaving}
                        required
                    />
                    <p className="text-muted">Note: Only emails from @gocbit.com are valid while Azure AD is the only authentication method.</p>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {props.groups.map((group, i) => 
                        <div className="form-check form-switch" key={i}>
                            <input className="form-check-input" type="checkbox" role="switch" id={`group${group.name}Switch`} name={`group${group.name}`} checked={props.workingUser.groups.includes(group.name)} onChange={handleChange}/>
                            <label className="form-check-label" htmlFor={`group${group.name}Switch`}>{group.name}</label>
                        </div>
                    )}
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    {props.choices.length > 1 ? 
                    <button className="btn btn-danger float-start" onClick={props.choices[1].func} disabled={props.isSaving}>
                        <i className="fas fa-times"/>&nbsp;Delete Permanently
                    </button>
                    :null}
                    <button type="submit" className="btn btn-primary float-end" onClick={handleSave} disabled={props.saveDisabled}>
                        <i className={`${props.isSaving ? 'fas fa-spinner' : 'fas fa-floppy-disk'}`}/>&nbsp;{props.isSaving ? props.savingLabel : 'Save & Close'}
                    </button>
                    <button className="btn btn-secondary me-2 float-end" onClick={props.choices[0].func} disabled={props.isSaving}>
                        <i className="fas fa-arrow-left"/>&nbsp;Close
                    </button>
                </div>
            </div>
        </div>
    )
}

const UserFormModal = props => {
    const [workingUser, setWorkingUser] = useState(props.user ? props.user : DEFAULT_USER);
    const [savedUser, setSavedUser] = useState(props.user ? props.user : null);
    const [groups, setGroups] = useState(null);
    const [modified, setModified] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [savingLabel, setSavingLabel] = useState('Saving...');
    const [saveMode, setSaveMode] = useState(props.user ? 'PUT' : 'POST');
    const modaling = useContext(ModalContext);

    useEffect(() => {
        if(groups === null) {
            chimera.callAPI(props.signal, '/api/groups')
            .then(newGroups => setGroups(newGroups))
            .catch(err => {
                console.error(err);
                if(props.parentBanners) {
                    props.parentBanners.addBanner('danger', 'Failed to read Groups; could not load form', 'Users Error');
                }
                if(props.modalContext) {
                    props.modalContext.setModal(null);
                }
            })
        }
    }, [groups]);

    const saveDisabled = () => {
        if(isSaving) return true;
        if(saveMode === 'PUT') {
            return chimera.deepEqual(workingUser, savedUser);
        }
        else {
            return !modified;
        }
    }

    const handleSave = (user) => {
        setIsSaving(true);
        setSavingLabel('Saving...');
        if(!user) user = workingUser;
        return new Promise(async(resolve, reject) => {
            let newSavedUser;
            if(saveMode === "POST") {
                try {
                    newSavedUser = await chimera.callAPI(props.signal, '/api/users', 'POST', {user});
                }
                catch(err) {
                    reject(err);
                    return;
                }
            }
            else { // saveMode === "PUT"
                try {
                    newSavedUser = await chimera.callAPI(props.signal, `/api/users/${user._id}`, 'PUT', user);
                }
                catch(err) {
                    reject(err);
                }
            }
            resolve(newSavedUser);
        })
    }

    const saveAndCloseFunc = (resolve, reject) => {
        const dismiss = (event) => {
            event.preventDefault();
            modaling.backtrack();
        }
        modaling.setModal(<Modal choices={[]} dismiss={dismiss}>
            <LoadingSpinner size={75}/>
        </Modal>)
        handleSave()
        .then(result => resolve(result))
        .catch(err => reject(err));
    }

    const saveAndCloseResolve = (savedUser) => {
        setWorkingUser(savedUser);
        setSavedUser(savedUser);
        setSaveMode('PUT');
        props.onClose();
        modaling.setModal(null);
    }

    const saveAndCloseReject = (err) => {
        console.error(err);
        alert("ERROR: Failed to save the User");
        modaling.backtrack();
    }

    const choices = [
        choiceCancel(props, !saveDisabled(), <>
            <h3>You have unsaved changes</h3>
            <p>Are you sure you want to close this form? Your changes will not be saved.</p>
        </>, "Close", {func: saveAndCloseFunc, resolve: saveAndCloseResolve, reject: saveAndCloseReject})
    ]

    if(saveMode === 'PUT' && savedUser !== null) {
        choices.push(choiceDelete(props, async() => {
            modaling.setModal(<Modal choices={[]} dismiss={(e) => {e.preventDefault(); modaling.setModal(null)}}>
                <LoadingSpinner size={75}/>
            </Modal>);
            try {
                await chimera.callAPI(props.signal, `/api/users/${savedUser._id}`, 'DELETE');
                if(props.parentBanners) props.parentBanners.addBanner('info', `User "${savedUser.first} ${savedUser.last}" (ID: ${savedUser._id}) has been deleted.`, 'Success');
            }
            catch(err) {
                console.error(err);
                if(err.status === 403) {
                    if(props.parentBanners) props.parentBanners.addBanner('danger', 'You do not have permission to do that.', 'Forbidden');
                }
                else {
                    if(props.parentBanners) props.parentBanners.addBanner('danger', `Failed to delete User (ID: ${savedUser._id})`, 'Error');
                }
            }
            props.onClose();
            modaling.setModal(null);
        }, {label: 'Delete Permanently'}))
    }
    
    return (
    <Modal choices={[]} dismiss={choices[0].func}>
        <BannerLog>
            {workingUser && groups && (!props.user || savedUser) ? 
            <UserFormModalBody {...props} 
                workingUser={workingUser} setWorkingUser={setWorkingUser} 
                savedUser={savedUser} setSavedUser={setSavedUser}
                modifed={modified} setModified={setModified}
                isSaving={isSaving} setIsSaving={setIsSaving}
                saveMode={saveMode} setSaveMode={setSaveMode}
                saveDisabled={saveDisabled()}
                handleSave={handleSave}
                choices={choices}
                savingLabel={savingLabel} setSavingLabel={setSavingLabel}
                groups={groups} setGroups={setGroups}
            />
            : <LoadingSpinner size={50}/>}
        </BannerLog>
    </Modal>)
}

const UsersPanel = ({bannerContext}) => {
    const [users, setUsers] = useState(null);
    const [controller] = useState(new AbortController());
    const [signal] = useState(controller.signal);
    const [enableAutogenerate, setEnableAutogenerate] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const modaling = useContext(ModalContext);

    useEffect(() => {
        if(!users) {
            chimera.callAPI(signal, '/api/users')
            .then(newUsers => setUsers(newUsers))
            .catch(err => {
                console.error(err);
                if(bannerContext) {
                    bannerContext.addBanner('danger', 'Failed to read Users', 'Users Error');
                }
            })
        }
    }, [users]);

    useEffect(() => {
        if(enableAutogenerate === null) {
            chimera.callAPI(signal, '/api/attributes/ENABLE_USER_AUTOGENERATE')
            .then(attr => {
                setEnableAutogenerate(attr.value);
            })
            .catch(err => {
                console.error(err);
                if(bannerContext) {
                    bannerContext.addBanner('danger', 'Failed to read Attributes; could not load form', 'Users Error');
                }
                if(bannerContext) {
                    bannerContext.setModal(null);
                }
            })
        }
    }, [enableAutogenerate]);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        const name = event.target.name;
        const value = event.target.type !== "checkbox" ? event.target.value : event.target.checked;

        if(name === "enableAutogenerate") {
            setEnableAutogenerate(value);
            setIsSaving(true);
            chimera.callAPI(undefined, '/api/attributes', 'PUT', {name: 'ENABLE_USER_AUTOGENERATE', value: value})
            .catch(err => {
                console.error(err);
                setEnableAutogenerate(!value);
                if(bannerContext) {
                    bannerContext.addBanner('danger', 'Failed to save changes', 'Error');
                }
            })
            .finally(() => {
                setIsSaving(false);
            })
        }
    }

    const handleClickedUser = (user) => {
        modaling.setModal(null);
        modaling.setModal(<UserFormModal parentBanners={bannerContext} modalContext={modaling} onClose={() => {setUsers(null)}} user={user}/>);
    }

    /** TODO: Implement exporting for Users */
    const openExportModal = (selectedObjects) => {
        modaling.setModal(<ExportModal objects={selectedObjects} model="user"/>);
    }

    const openNewUserForm = (_) => {
        modaling.setModal(null);
        modaling.setModal(<UserFormModal parentBanners={bannerContext} modalContext={modaling} onClose={() => {setUsers(null)}}/>)
    }

    return (
        <>
        {users === null || enableAutogenerate === null ? 
            <LoadingSpinner size={75}/>
        :
        <>
        <div className="form-check form-switch text-start">
            <input className="form-check-input" type="checkbox" role="switch" id='enableAutogenerateSwitch' name='enableAutogenerate' checked={enableAutogenerate} onChange={handleChange} disabled={isSaving}/>
            <label className="form-check-label" htmlFor='enableAutogenerateSwitch'><b>Auto-Generate Users on First Login?</b>&nbsp;<Tooltip pos='bottom' text="When enabled, any user on the @gocbit.com tenant in Azure AD is able to create a Chimera account for themselves automatically by signing in for the first time. This can be disabled for stricter control on access, where users will need to be created on this page first before they can log in successfully."><i className="fas fa-circle-question"/></Tooltip></label>
        </div>
        <ObjectTable 
            id="usersTable"
            cols={[
                {
                    label: 'First Name', 
                    sort: (a, b) => a.first < b.first ? -1 : 1,
                    render: (obj) => obj.first
                },
                {
                    label: 'Last Name',
                    sort: (a, b) => a.last < b.last ? -1 : 1,
                    render: (obj) => obj.last
                },
                {
                    label: 'Email',
                    sort: (a, b) => a.email < b.email ? -1 : 1,
                    render: (obj) => obj.email
                },
                {
                    label: 'Groups',
                    sort: (a, b) => a.groups.join(', ') < b.groups.join(', ') ? -1 : 1,
                    render: (obj) => getGroupsPreview(obj)
                }
            ]}
            objects={users}
            actions={[
                {label: 'Export', func: openExportModal},
            ]}
            btns={[
                {label: 'Add User', func: openNewUserForm}
            ]}
            onClicked={handleClickedUser}
            defaultSortByColName="Last Name"
            defaultSortAscending={true}
        />
        </>
        }
        </>
    )
}

export default UsersPanel;