import React, {useState, useEffect, useContext} from "react";
import { NavLink } from "react-router-dom";
import chimeralogo from "../images/chimeralogo.png";
import UserContext from "../UserContext";
import AlertsContext from "./AlertsContext";
import AlertBubble from "./AlertBubble";

const Navigation = props => {
    const [inTool, setInTool] = useState(false);
    const [inBackups, setInBackups] = useState(false);
    const [query, setQuery] = useState("");
    const context = useContext(UserContext);
    const alertsContext = useContext(AlertsContext);

    useEffect(() => {
        setInterval(() => {
            setInTool(window.location.pathname.startsWith('/tools/'));
            setInBackups(window.location.pathname.startsWith('/backups/'));
        }, 50);
    }, []);

    const hasTool = (tool) => {
        return context.tools.includes(tool) || context.tools.includes("ALL");
    }

    const handleChange = (event) => {
        if(event.target.name === "search") {
            setQuery(event.target.value);
        }
    }

    const handleKeyDown = (event) => {
        if(event.key === "Enter" && query !== "") {
            window.open(`/search?q=${query}`, '_self');
        }
    }

    /** This function is necessary because otherwise the pathname + search parts are ignored (probably cached and Navigation isn't updated) */
    const goToDevClient = (event) => {
        event.preventDefault();
        try {
            window.open(`http://localhost:3000${window.location.pathname + window.location.search}`, '_self');
        }
        catch(err) {
            console.error(err);
        }
    }

    return (
        <div className="navigation">
            <nav className="navbar navbar-expand navbar-dark bg-dark">
                <div className="container">
                    <NavLink className="navbar-brand" to="/">
                        <img src={chimeralogo} alt="Chimera logo" style={{filter: "brightness(90%)"}}/>
                    </NavLink>
                    {context.user ? 
                    <>
                    <input className="form-control searchbar me-auto" name="search" type="text" placeholder="Search everything" value={query} onChange={handleChange} onKeyDown={handleKeyDown}/>
                    <div>
                        <ul className="navbar-nav ml-auto">
                            {/** Used to switch from port 3000 (the express server) to port 3001 (the React dev server) */}
                            {window.location.href.startsWith("http://localhost:3001") ? <li className="nav-item">
                                <a href="#" className="nav-link" onClick={goToDevClient}>
                                    <span><i className="fas fa-exclamation-triangle"/>&nbsp;Go to Dev Client</span>
                                </a>
                            </li> : null}
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/help">
                                    <span><i className="fas fa-circle-question"/>&nbsp;Help</span>
                                </NavLink>
                            </li>
                            {context.user && context.permissions && context.permissions.admin.read ? 
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/admin">
                                    <span><i className="fas fa-user-tie"/>&nbsp;Admin</span>
                                </NavLink>
                            </li>
                            :null}
                            <li className="nav-item dropdown">
                                <button className={`nav-link dropdown-toggle btn`} id="userDropdown"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span><i className="fas fa-id-card"/>&nbsp;{context.user.first} {context.user.last}</span>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="userDropdown">
                                    <li>
                                        <a className="dropdown-item" href="/auth/logout">
                                            Log Out
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    </>
                    : null}
                </div>
            </nav>
            {context.user ? 
            <nav className="navbar navbar-expand navbar-dark bg-chimera">
                <div className="container">
                    <ul className="navbar-nav mx-auto">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/orders/products">
                                <span><i className="fas fa-receipt position-relative"></i>&nbsp;Orders{alertsContext.getOrdersTotal() > 0 ? <>&nbsp;<AlertBubble inline>{alertsContext.getOrdersTotal()}</AlertBubble></> : null}</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/customers">
                                <span><i className="fas fa-address-book"/>&nbsp;Customers</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/projects">
                                <span><i className="fas fa-list-check"/>&nbsp;Projects{alertsContext.getProjectsTotal() > 0 ? <>&nbsp;<AlertBubble inline>{alertsContext.getProjectsTotal()}</AlertBubble></> : null}</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/residential">
                                <span><i className="fas fa-house"/>&nbsp;Residential</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/vendors">
                                <span><i className="fas fa-briefcase"/>&nbsp;Vendors</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/reports">
                                <span><i className="fas fa-chart-line"/>&nbsp;Reports</span>
                            </NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <button className={`nav-link${inBackups ? ' active' : ''} dropdown-toggle btn`} id="backupsDropdown"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span><i className="fas fa-desktop"/>&nbsp;Backups</span>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="backupsDropdown">
                                <li>
                                    <NavLink className="dropdown-item" to="/backups/unity">
                                        Unity
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="dropdown-item" to="/backups/chimera">
                                        Chimera
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        {(context.tools.length > 0) ? 
                            <li className="nav-item dropdown">
                                <button className={`nav-link${inTool ? ' active' : ''} dropdown-toggle btn`} id="toolsDropdown"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span><i className="fas fa-toolbox"/>&nbsp;Tools</span>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="toolsDropdown">
                                    {hasTool("syncroinvoice") ? 
                                    <li>
                                        <NavLink className="dropdown-item" to="/tools/syncroinvoice">
                                            Syncro Invoice
                                        </NavLink>
                                    </li>
                                    : null }
                                    {hasTool("customfields") ? 
                                    <li>
                                        <NavLink className="dropdown-item" to="/tools/customfields">
                                            Syncro Custom Fields
                                        </NavLink>
                                    </li>
                                    : null }
                                    {hasTool("qbreports") ? 
                                    <li>
                                        <NavLink className="dropdown-item" to="/tools/qbreports">
                                            QB Reports
                                        </NavLink>
                                    </li>
                                    : null }
                                    {hasTool("cacns") ? 
                                    <li>
                                        <NavLink className="dropdown-item" to="/tools/cacns">
                                            CACN Retreival
                                        </NavLink>
                                    </li>
                                    : null }
                                    {hasTool("taxcalc") ? 
                                    <li>
                                        <NavLink className="dropdown-item" to="/tools/taxcalc">
                                            Tax Calculator
                                        </NavLink>
                                    </li>
                                    : null }
                                    {hasTool("commit") ? 
                                    <li>
                                        <NavLink className="dropdown-item" to="/tools/commit">
                                            Commit Toggler
                                        </NavLink>
                                    </li>
                                    : null }
                                    {hasTool("audit") ? 
                                    <li>
                                        <NavLink className="dropdown-item" to="/tools/audit">
                                            Auditing
                                        </NavLink>
                                    </li>
                                    : null }
                                    {hasTool("tags") ? 
                                    <li>
                                        <NavLink className="dropdown-item" to="/tools/tags">
                                            Customer Tags
                                        </NavLink>
                                    </li>
                                    : null }
                                    {hasTool("customerchecker") ? 
                                    <li>
                                        <NavLink className="dropdown-item" to="/tools/customerchecker">
                                            QB Customer Checker
                                        </NavLink>
                                    </li>
                                    : null }
                                    {hasTool("techbill") ? 
                                    <li>
                                        <NavLink className="dropdown-item" to="/tools/techbill">
                                            Technician Billability
                                        </NavLink>
                                    </li>
                                    : null }
                                    {hasTool("txntrackers") ? 
                                    <li>
                                        <NavLink className="dropdown-item" to="/tools/txntrackers">
                                            Transaction Trackers
                                        </NavLink>
                                    </li>
                                    : null }
                                    {hasTool("agenttool") ? 
                                    <li>
                                        <NavLink className="dropdown-item" to="/tools/agenttool">
                                            Agent Tool
                                        </NavLink>
                                    </li>
                                    : null }
                                    {context.user.email === "austin@gocbit.com" ? 
                                    <li>
                                        <NavLink className="dropdown-item" to="/tools/patchnotes">
                                            Write Patch Notes
                                        </NavLink>
                                    </li>
                                    : null }
                                </ul>
                            </li>
                        : null}
                    </ul>
                </div>
            </nav>
            :null}
        </div>
    );
}

export default Navigation;