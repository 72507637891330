import React, {useState, useEffect, useContext} from 'react';

import BannerContext from './BannerLogContext';
import LoadingSpinner from './LoadingSpinner';
import FormFieldMicro from './FormFieldMicro';
import chimera from '../chimera';
import ModalContext from './ModalContext';
import Modal, {choiceCancel, choiceDelete} from './Modal';
import Checklist from './Checklist';
import Tooltip from './Tooltip';
import MiniNotes from './MiniNotes';
import FileUpload from './FileUpload';
import AutocompleteNew from './AutocompleteNew';

const SmartFormContext = React.createContext();

// TODO: readonly implementation for all subcomponents
const SmartForm = ({formId, noun, workingObject, setWorkingObject, modified, setModified, saveAndClose, handleChange, handleSave, readonly, disabled, children}) => {
    const [isSaving, setIsSaving] = useState(false);
    const banners = useContext(BannerContext);
    const modaling = useContext(ModalContext);
    //const formId = useId(); // not supported in React 17

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSaving(true);
        _handleSave(workingObject, saveAndClose);
    }

    const _handleSave = (objToSave, andClose) => {
        return new Promise((resolve, reject) => {
            handleSave(objToSave)
            .then(savedObj => {
                if(banners) {
                    if(workingObject._id) {
                        banners.addBanner('info', 'Changes saved successfully', 'Saved');
                    }
                    else {
                        banners.addBanner('info', `Created new ${noun}`, 'Saved');
                    }
                }
                setWorkingObject(savedObj);
                setIsSaving(false);
                setModified(false);
                // TODO: implement page overlay version instead of modal opening version, and update this to support it.
                if(saveAndClose && andClose && modaling) {
                    resolve(savedObj);
                    modaling.backtrack();
                }
                else {
                    resolve(savedObj);
                }
            })
            .catch(err => {
                console.error(err);
                if(banners) {
                    if(err.details && err.details.name === "ValidationError") {
                        for(const key in err.details.errors) {
                            banners.addBanner('danger', `${key}: ${err.details.errors[key].message}`, 'Validation Error');
                        }
                    }
                    else if(err.name !== 'AbortError') {
                        banners.addBanner('danger', 'Failed to save order', 'Error');
                    }
                }
                else {
                    alert(`ERROR: An unhandled error occurred and the ${noun} could not be saved.`);
                }
                setIsSaving(false);
                reject(err);
            })
        })
    }

    const _handleChange = (e) => {
        setModified(true);
        handleChange(e);
    }

    const getValue = (path) => {
        return chimera.getAttr(workingObject, path);
    }

    return(
        <form id={formId} onSubmit={handleSubmit} noValidate>
            <SmartFormContext.Provider value={{workingObject, setWorkingObject, saveAndClose, readonly, modified, setModified, getValue, handleChange: _handleChange, handleSave: _handleSave, isSaving, disabled: disabled || isSaving}}>
                {workingObject !== null ? 
                    <div className="row">
                        {children}
                    </div>
                : <LoadingSpinner size={50}/>}
            </SmartFormContext.Provider>
        </form>
    )
}

/** TODO: Stretch to height of viewport, enable scrolling within the component itself */
const Main = ({children}) => {
    return (
        <div className="col">
            {children}
        </div>
    )
}
SmartForm.Main = Main;

/** TODO: Stays static (no scrolling) at least compared to Main. Could let Main scroll with the page instead of being scrollable within itself for full-page forms, but scrollable Main is for Modals (which we should try to get away from tbh)*/
const Sidebar = ({children}) => {
    return (
        <div className="col-3 border-start">
            {children}
        </div>
    )
}
SmartForm.Sidebar = Sidebar;

/** BUTTONS START */
const SaveButton = ({fullWidth}) => {
    const smartFormContext = useContext(SmartFormContext);
    return(
        <button className={`btn mb-1 btn-primary${fullWidth ? ' w-100' : ''}`} type="submit" disabled={smartFormContext.disabled || !smartFormContext.modified}>
            <i className={smartFormContext.isSaving ? 'fas fa-spinner' : 'fas fa-floppy-disk'}/>&nbsp;{smartFormContext.isSaving ? 'Saving...' : (smartFormContext.saveAndClose ? 'Save & Close' : 'Save')}
        </button>
    )
}
SmartForm.SaveButton = SaveButton;

const DeleteButton = ({}) => {
    return (
        <></>
    )
}
SmartForm.DeleteButton = DeleteButton;

const ActionButton = ({icon, label, pendingLabel, color, action, disableWhenModified, disabled, fullWidth}) => {
    const smartFormContext = useContext(SmartFormContext);
    const [isPending, setIsPending] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();
        setIsPending(true);
        action()
        .finally(() => {
            setIsPending(false);
        })
    }

    return (
        <button className={`btn mb-1 btn-${color ? color : 'primary'}${fullWidth ? ' w-100' : ''}`} onClick={handleClick} disabled={(disableWhenModified && smartFormContext.modified) || disabled || isPending || smartFormContext.disabled}>
            <i className={isPending ? 'fas fa-spinner' : icon}/>&nbsp;{isPending ? (pendingLabel ? pendingLabel : 'Working...') : label}
        </button>
    )
}
SmartForm.ActionButton = ActionButton;

/** BUTTONS END */
/** FIELDS START */

/**
 * 
 * @param {String} path The path to the value from `workingObject` 
 * @param {String} label The label displayed on the `FormFieldMicro`
 * @param {Number} size (optional) An integer size limit for the text input
 * @param {Boolean} fullWidth (optional) Whether to pass `fullWidth` or `fit` to the `FormFieldMicro`
 * @returns 
 */
const StringField = ({path, label, size, required, fullWidth}) => {
    const smartFormContext = useContext(SmartFormContext);

    const trimOnBlur = (event) => {
        smartFormContext.handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    return (
        <FormFieldMicro
            type="text"
            name={path}
            label={label}
            value={smartFormContext.getValue(path)}
            handleChange={smartFormContext.handleChange}
            onBlur={trimOnBlur}
            disabled={smartFormContext.disabled}
            required={required}
            size={size}
            fullWidth={fullWidth}
            fit={!fullWidth}
        />
    )
}
SmartForm.StringField = StringField;

/**
 * @param {String} path The path to the value from `workingObject` 
 * @param {String} label The label displayed on the `FormFieldMicro`
 * @param {Array<{label: String, value: any}>} options 
 * @param {Boolean} fullWidth (optional) Whether to pass `fullWidth` or `fit` to the `FormFieldMicro`
 * @param {Boolean} excludeNoneSelected (optional) Whether to exclude "-- NONE SELECTED --" option from dropdown
 * @returns A rendered `<select>` form element
 */
const SelectField = ({path, label, options, required, fullWidth, excludeNoneSelected}) => {
    const smartFormContext = useContext(SmartFormContext);

    return (
        <FormFieldMicro
            type="select"
            name={path}
            label={label}
            value={smartFormContext.getValue(path)}
            handleChange={smartFormContext.handleChange}
            options={options.map(opt => {return {id: opt.value, value: opt.label}})} // `id` is the value and `value` is the label. this is bad, please fix later
            disabled={smartFormContext.disabled}
            fullWidth={fullWidth}
            fit={!fullWidth}
            required={required}
            excludeNoneSelected={excludeNoneSelected}
        />
    )
}
SmartForm.SelectField = SelectField;

const DollarField = ({path, label, required, fullWidth}) => {
    const smartFormContext = useContext(SmartFormContext);

    const trimOnBlur = (event) => {
        smartFormContext.handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    return (
        <FormFieldMicro
            type="text"
            name={path}
            label={label}
            value={smartFormContext.getValue(path)}
            handleChange={smartFormContext.handleChange}
            onBlur={trimOnBlur}
            disabled={smartFormContext.disabled}
            size={10}
            placeholder={"$0.00"}
            pattern={"\\$?[\\d\\,]+\\.\\d\\d"}
            required={required}
            fit={!fullWidth}
            fullWidth={fullWidth}
        />
    )
}
SmartForm.DollarField = DollarField;

const ChecklistField = ({path, label, required, fullWidth}) => {
    const smartFormContext = useContext(SmartFormContext);

    const setItems = (newList) => {
        const newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObject));
        chimera.setAttr(newWorkingObj, path, newList);
        smartFormContext.setWorkingObject(newWorkingObj);
        smartFormContext.setModified(true);
    }

    return (
        <FormFieldMicro
            type="component"
            name={path}
            label={label}
            required={required}
            fit={!fullWidth}
            fullWidth={fullWidth}
        >
            <Checklist name={path} items={smartFormContext.getValue(path)} setItems={setItems} isEditing={!smartFormContext.readonly} disabled={smartFormContext.disabled} fullWidth={fullWidth}/>
        </FormFieldMicro>
    )
}
SmartForm.ChecklistField = ChecklistField;

const CustomerSelector = ({path, label, required}) => {
    const [customers, setCustomers] = useState(null);
    const smartFormContext = useContext(SmartFormContext);
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(customers === null) {
            chimera.callAPI(undefined, '/api/customers')
            .then(newCustomers => setCustomers(newCustomers))
            .catch(err => {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', 'Failed to load Customers; Customer selection will not be available.', 'Error');
                }
                else {
                    alert('ERROR: Failed to load Customers; Customer selection will not be available.');
                }
            })
        }
    }, [customers]);

    const suggestionChosenCallback = (customer) => {
        const oldValues = smartFormContext.getValue(path)
        const newValues = {
            name: customer ? customer.displayName : '',
            ref: customer ? customer.accountNumber : '',
            qbId: customer ? customer.integrationIds.quickbooks : '',
            locationNickname: customer ? customer.locations[0].nickname : ''
        }
        if(!chimera.deepEqual(oldValues, newValues)) {
            const newObject = JSON.parse(JSON.stringify(smartFormContext.workingObject));
            chimera.setAttr(newObject, path, newValues);
            smartFormContext.setWorkingObject(newObject);
            smartFormContext.setModified(true);
        }
    }

    return (
        <AutocompleteNew
            label={`${label ? label : 'Customer Name'}:`}
            value={smartFormContext.getValue(path).ref}
            objects={customers}
            labelRule={(c) => c.displayName}
            valueRule={(c) => c.accountNumber}
            objectChosenCallback={suggestionChosenCallback}
            strictMode
            required={required}
            disabled={smartFormContext.disabled}
            isLoading={customers === null}
        />
    )
}
SmartForm.CustomerSelector = CustomerSelector;

const AssigneeSelector = ({path, label, required}) => {
    const [users, setUsers] = useState(null);
    const smartFormContext = useContext(SmartFormContext);
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(users === null) {
            chimera.callAPI(undefined, '/api/users')
            .then(newUsers => setUsers(newUsers))
            .catch(err => {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', 'Failed to load Users; Assignee selection will not be available.', 'Error');
                }
                else {
                    alert('ERROR: Failed to load Users; Assignee selection will not be available.');
                }
            })
        }
    }, [users]);

    const suggestionChosenCallback = (user) => {
        const oldValues = smartFormContext.getValue(path)
        const newValues = {
            first: user ? user.first : '',
            last: user ? user.last : '',
            email: user ? user.email : ''
        }
        if(!chimera.deepEqual(oldValues, newValues)) {
            const newObject = JSON.parse(JSON.stringify(smartFormContext.workingObject));
            chimera.setAttr(newObject, path, newValues);
            smartFormContext.setWorkingObject(newObject);
            smartFormContext.setModified(true);
        }
    }

    return (
        <AutocompleteNew
            label={`${label ? label : 'Assignee'}:`}
            value={smartFormContext.getValue(path).email}
            objects={users}
            labelRule={(u) => `${u.first} ${u.last}`}
            valueRule={(u) => u.email}
            objectChosenCallback={suggestionChosenCallback}
            strictMode
            required={required}
            disabled={smartFormContext.disabled}
            isLoading={users === null}
        />
    )
}
SmartForm.AssigneeSelector = AssigneeSelector;

/**
 * 
 * @param {String} path The path to the QB Invoice (subschema), not to the number directly 
 * @returns 
 */
const QBInvoiceField = ({path, label, required, fullWidth}) => {
    const smartFormContext = useContext(SmartFormContext);
    const banners = useContext(BannerContext);

    const [isVerifying, setIsVerifying] = useState(false);

    // override form's handleChange so we can reset qbInvoice.id and qbInvoice.status
    const handleChange = (e) => {
        e.preventDefault();
        let newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObject));
        chimera.setAttr(newWorkingObj, e.target.name, e.target.value);
        chimera.setAttr(newWorkingObj, `${path}.id`, '');
        chimera.setAttr(newWorkingObj, `${path}.status`, 'NOT SET');
        smartFormContext.setWorkingObject(newWorkingObj);
        smartFormContext.setModified(true);
    }

    const trimOnBlur = (event) => {
        smartFormContext.handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const openInvoiceInNewTab = (e) => {
        e.preventDefault();
        window.open(`https://app.qbo.intuit.com/app/invoice?txnId=${smartFormContext.getValue(`${path}.id`)}`, '_blank');
    }

    const performLookup = (e) => {
        e.preventDefault();
        setIsVerifying(true);
        chimera.callQuickBooksAPI(undefined, `/api/qb/invoice/${smartFormContext.getValue(`${path}.number`)}`)
        .then(invoice => {
            const newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObject));
            chimera.setAttr(newWorkingObj, `${path}.id`, invoice.Id);
            chimera.setAttr(newWorkingObj, `${path}.status`, invoice.Balance === 0 ? "Paid" : "Unpaid");
            smartFormContext.setWorkingObject(newWorkingObj);
            smartFormContext.setModified(true);
        })
        .catch(err => {
            console.error(err);
            if(banners) {
                banners.addBanner('danger', `Failed to verify QB Invoice #${smartFormContext.getValue(`${path}.number`)}`, 'QB Error');
            }
            else {
                alert(`ERROR: Failed to verify QB Invoice #${smartFormContext.getValue(`${path}.number`)}`);
            }
        })
        .finally(() => {
            setIsVerifying(false);
        })
    }

    return (
        <FormFieldMicro
            type="text"
            name={`${path}.number`}
            label={label}
            value={smartFormContext.getValue(`${path}.number`)}
            handleChange={handleChange}
            onBlur={trimOnBlur}
            disabled={smartFormContext.disabled}
            required={required}
            size={6}
            fit={!fullWidth}
            fullWidth={fullWidth}
        >
            <div className="mt-1">
                <Tooltip pos="bottom" text="Verify Invoice">
                    <button className="btn btn-secondary btn-sm me-1" onClick={performLookup} disabled={smartFormContext.disabled || isVerifying || smartFormContext.getValue(`${path}.number`) === ''}>
                        <i className={isVerifying ? "fas fa-spinner" : "fas fa-rotate"}/>
                    </button>
                </Tooltip>
                <Tooltip pos="bottom" text="Open QB Invoice in New Tab">
                    <button className="btn btn-secondary btn-sm" onClick={openInvoiceInNewTab} disabled={smartFormContext.getValue(`${path}.id`) === ''}>
                        <i className="fas fa-up-right-from-square"/>
                    </button>
                </Tooltip>
                <br/>
                <span>Verified:&nbsp;<i className={smartFormContext.getValue(`${path}.id`) ? 'fas fa-check text-success' : 'fas fa-times text-danger'}/></span>
                <br/>
                <span>Status: {smartFormContext.getValue(`${path}.status`) !== "NOT SET" ? smartFormContext.getValue(`${path}.status`) : "(Verify first)"}</span>
            </div>
        </FormFieldMicro>
    )
}
SmartForm.QBInvoiceField = QBInvoiceField;

const QBEstimateField = ({path, label, required, fullWidth}) => {
    const smartFormContext = useContext(SmartFormContext);
    const banners = useContext(BannerContext);

    const [isVerifying, setIsVerifying] = useState(false);

    // override form's handleChange so we can reset the ID
    const handleChange = (e) => {
        e.preventDefault();
        let newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObject));
        chimera.setAttr(newWorkingObj, e.target.name, e.target.value);
        chimera.setAttr(newWorkingObj, `${path}.id`, '');
        smartFormContext.setWorkingObject(newWorkingObj);
        smartFormContext.setModified(true);
    }

    const trimOnBlur = (event) => {
        smartFormContext.handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const openEstimateInNewTab = (e) => {
        e.preventDefault();
        window.open(`https://app.qbo.intuit.com/app/estimate?txnId=${smartFormContext.getValue(`${path}.id`)}`, '_blank');
    }

    const performLookup = (e) => {
        e.preventDefault();
        setIsVerifying(true);
        chimera.callQuickBooksAPI(undefined, `/api/qb/estimate/${smartFormContext.getValue(`${path}.number`)}`)
        .then(estimate => {
            const newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObject));
            chimera.setAttr(newWorkingObj, `${path}.id`, estimate.Id);
            smartFormContext.setWorkingObject(newWorkingObj);
            smartFormContext.setModified(true);
        })
        .catch(err => {
            console.error(err);
            if(banners) {
                banners.addBanner('danger', `Failed to verify QB Estimate #${smartFormContext.getValue(`${path}.number`)}`, 'QB Error');
            }
            else {
                alert(`ERROR: Failed to verify QB Estimate #${smartFormContext.getValue(`${path}.number`)}`);
            }
        })
        .finally(() => {
            setIsVerifying(false);
        })
    }

    return (
        <FormFieldMicro
            type="text"
            name={`${path}.number`}
            label={label}
            value={smartFormContext.getValue(`${path}.number`)}
            handleChange={handleChange}
            onBlur={trimOnBlur}
            disabled={smartFormContext.disabled}
            required={required}
            size={6}
            fit={!fullWidth}
            fullWidth={fullWidth}
        >
            <div className="mt-1">
                <Tooltip pos="bottom" text="Verify Invoice">
                    <button className="btn btn-secondary btn-sm me-1" onClick={performLookup} disabled={smartFormContext.disabled || isVerifying || smartFormContext.getValue(`${path}.number`) === ''}>
                        <i className={isVerifying ? "fas fa-spinner" : "fas fa-rotate"}/>
                    </button>
                </Tooltip>
                <Tooltip pos="bottom" text="Open QB Invoice in New Tab">
                    <button className="btn btn-secondary btn-sm" onClick={openEstimateInNewTab} disabled={smartFormContext.getValue(`${path}.id`) === ''}>
                        <i className="fas fa-up-right-from-square"/>
                    </button>
                </Tooltip>
                <br/>
                <span>Verified:&nbsp;<i className={smartFormContext.getValue(`${path}.id`) ? 'fas fa-check text-success' : 'fas fa-times text-danger'}/></span>
            </div>
        </FormFieldMicro>
    )
}
SmartForm.QBEstimateField = QBEstimateField;

const Notes = ({path}) => {
    const smartFormContext = useContext(SmartFormContext);

    const setNotes = (newNotes) => {
        const newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObject));
        chimera.setAttr(newWorkingObj, path, newNotes);
        smartFormContext.setWorkingObject(newWorkingObj);
        smartFormContext.setModified(true);
    }

    return (
        <MiniNotes notes={smartFormContext.getValue(path)} setNotes={setNotes} disabled={smartFormContext.disabled}/>
    )
}
SmartForm.Notes = Notes;

/** FIELDS END */
/** SECTIONS START */

const Section = ({children, nCols, bordered}) => {
    // for some reason when there is only one child it is not passed as an array, so we turn it into one
    const childrenArr = () => {
        if(children.length) return children;
        else return [children];
    }

    return (
        <div className={bordered ? "section-outline" : ""}>
            <div className={`row row-cols-1 row-cols-md-${nCols} g-${nCols}`}>
                {childrenArr().map((child, i) => <div key={i} className="col">{child}</div>)}
            </div>
        </div>
    )
}
SmartForm.Section = Section;

const Divider = ({}) => {
    return (
        <hr/>
    )
}
SmartForm.Divider = Divider;

/** TODO: Include "Add Attachment" button within the section so attachment functionality relies on this subcomponent alone and isn't dependent upon an additional button */
const AttachmentsSection = ({path}) => {
    const smartFormContext = useContext(SmartFormContext);
    const modaling = useContext(ModalContext);
    const banners = useContext(BannerContext);

    const downloadAttachment = (attachment) => {
        const modal = <Modal choices={[]} dismiss={(e) => {e.preventDefault(); modaling.backtrack();}}>
            <LoadingSpinner size={75} label="Downloading..."/>
        </Modal>
        modaling.setModal(modal);

        chimera.callAPI(undefined, `/api/file/${attachment.id}`)
        .then(file => {
            fetch(`data:${file.type};base64,${file.content}`)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = file.filename;
                link.click();
            })
            .catch(err => {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', `Failed to download file (ID: ${attachment.id})`, 'Error');
                }
                else {
                    alert(`ERROR: Failed to download file (ID: ${attachment.id})`);
                }
            })
        })
        .catch(err => {
            console.error(err);
            if(banners) {
                banners.addBanner('danger', `Failed to download file (ID: ${attachment.id})`, 'Error');
            }
            else {
                alert(`ERROR: Failed to download file (ID: ${attachment.id})`);
            }
        })
        .finally(() => {
            modaling.backtrack();
        })
    }

    const sizeAbbreviation = (sizeInBytes) => {
        if(sizeInBytes >= 1000000) {
            return `${(sizeInBytes / 1000000).toFixed(1)} MB`;
        }
        else if(sizeInBytes >= 1000) {
            return `${(sizeInBytes / 1000).toFixed(1)} KB`;
        }
        else {
            return `${(sizeInBytes).toFixed(1)} B`;
        }
    }

    const deleteAttachment = (attachment) => {
        const choices = [
            choiceCancel({modalContext: modaling, backtrack: true}),
            choiceDelete(
                {modalContext: modaling},
                () => {
                    const modal = <Modal choices={[]} dismiss={(e) => {e.preventDefault(); modaling.backtrack(); modaling.backtrack();}}>
                        <LoadingSpinner size={75} label="Deleting..."/>
                    </Modal>
                    modaling.setModal(modal);
            
                    chimera.callAPI(undefined, `/api/file/${attachment.id}`, 'DELETE')
                    .then(async _ => {
                        const newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObject));
                        chimera.setAttr(newWorkingObj, path, chimera.getAttr(newWorkingObj, path).filter(a => a.id !== attachment.id));
                        try {
                            await smartFormContext.handleSave(newWorkingObj, false);
                        }
                        catch(err) {
                            // dont care
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        banners.addBanner('danger', 'Failed to delete file', 'Error');
                    })
                    .finally(() => {
                        modaling.backtrack();
                        modaling.backtrack();
                    })
                },
                {noConfirm: true}
            )
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Are you sure?</h3>
            <p>Are you sure you want to delete the attached file? This operation cannot be undone. Filename: {attachment.filename}</p>
        </Modal>
        modaling.setModal(modal);
    }

    const addAttachment = (file, contents) => {
        chimera.callAPI(undefined, '/api/file', 'POST', {
            filename: file.name,
            type: file.type,
            size: file.size,
            content: contents,
            encoding: 'base64'
        })
        .then(async savedFile => {
            const newWorkingObj = JSON.parse(JSON.stringify(smartFormContext.workingObject));
            let newAttachment = {
                filename: savedFile.filename,
                type: savedFile.type,
                size: savedFile.size,
                id: savedFile._id
            }
            if(chimera.getAttr(newWorkingObj, path) === undefined || chimera.getAttr(newWorkingObj, path) === null) {
                chimera.setAttr(newWorkingObj, path, [newAttachment]);
            }
            else {
                chimera.setAttr(newWorkingObj, path, [...chimera.getAttr(newWorkingObj, path), newAttachment]);
            }
            try {
                await smartFormContext.handleSave(newWorkingObj, false);
            }
            catch(err) {
                // dont care
            }
        })
        .catch(err => {
            console.error(err);
            if(banners) {
                banners.addBanner('danger', 'Failed to upload attachment', 'Error');
            }
            else {
                alert('ERROR: Failed to upload attachment');
            }
        })
        .finally(() => {
            modaling.backtrack();
        })
    }

    const openFileUploadModal = (event) => {
        event.preventDefault();
        const choices = [
            choiceCancel({modalContext: modaling, backtrack: true}),
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <FileUpload callback={addAttachment}/>
        </Modal>
        modaling.setModal(modal);
    }

    return (
        <div className="section-outline">
            <div className="row">
                <div className="col">
                    <h5 className="text-start">Attachments</h5>
                </div>
            </div>
            <div className="row">
                <div className="col text-start">
                    {!smartFormContext.getValue(path) || smartFormContext.getValue(path).length === 0 ? <span className="text-muted">There are no attachments yet</span> : <ol className="text-start">
                        {smartFormContext.getValue(path).map((attachment, i) => <li key={i}>
                            <span>
                                <a href="#" onClick={(e) => {e.preventDefault(); downloadAttachment(attachment)}}>
                                    {attachment.filename}
                                </a>
                                &nbsp;
                                ({sizeAbbreviation(attachment.size)})
                                &nbsp;
                                <button className="btn btn-sm btn-danger" onClick={(e) => {e.preventDefault(); deleteAttachment(attachment)}} disabled={smartFormContext.disabled}>
                                    <i className="fas fa-times"/>
                                </button>
                            </span>
                        </li>)}
                    </ol>}
                    <br/>
                    <button className="btn btn-success mt-2" onClick={openFileUploadModal} disabled={smartFormContext.disabled}>
                        <i className="fas fa-plus"/>&nbsp;Upload Attachment
                    </button>
                </div>
            </div>
        </div>
    )
}
SmartForm.AttachmentsSection = AttachmentsSection;

/** SECTIONS END */

export default SmartForm;