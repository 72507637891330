import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';
import chimera from '../../chimera';
import BannerContext, { BannerLog } from '../BannerLogContext';
import LoadingSpinner from '../LoadingSpinner';

const FCC477Body = props => {
    const [loading, setLoading] = useState(true);
    const [label, setLabel] = useState("");
    const [blob, setBlob] = useState(null);
    const [controller] = useState(new AbortController());
    const [signal] = useState(controller.signal);
    const banners = useContext(BannerContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        const headerStyle = {
            alignment: {
                horizontal: 'center',
                vertical: 'center',
                wrapText: true
            },
            font: {
                name: "Calibri",
                size: 10,
                bold: true
            },
            border: {
                right: {
                    style: "medium",
                    color: "black"
                },
                left: {
                    style: "medium",
                    color: "black"
                },
                top: {
                    style: "medium",
                    color: "black"
                },
                bottom: {
                    style: "medium",
                    color: "black"
                }
            }
        }
        const grayFill = {
            type: "pattern",
            patternType: "solid",
            fgColor: "#D9D9D9"
        }
        const tractHeaderStyle = JSON.parse(JSON.stringify(headerStyle));
        tractHeaderStyle.fill = grayFill;
        const tractCellStyle = {
            fill: grayFill,
            border: {
                top: {
                    style: "thin",
                    color: "black"
                },
                bottom: {
                    style: "thin",
                    color: "black"
                },
                left: {
                    style: "medium",
                    color: "black"
                },
                right: {
                    style: "medium",
                    color: "black"
                }
            }
        }
        const headers = [
            {
                string: "Customer",
                key: "customer",
                rowSpan: 2,
                style: headerStyle,
            },
            {
                string: "Street Address",
                key: "streetAddress",
                rowSpan: 2,
                style: headerStyle,
                width: 44.0
            },
            {
                string: "City",
                key: "city",
                rowSpan: 2,
                style: headerStyle,
            },
            {
                string: "County",
                key: "county",
                rowSpan: 2,
                style: headerStyle,
            },
            {
                string: "State",
                key: "state",
                rowSpan: 2,
                style: headerStyle,
            },
            {
                string: "Zip Code",
                key: "zip",
                rowSpan: 2,
                style: headerStyle,
            },
            {
                string: "Tract Code",
                key: "tract",
                rowSpan: 2,
                style: tractHeaderStyle,
            },
            {
                arr: [
                    {
                        string: "Service Type",
                        style: {
                            alignment: {
                                horizontal: 'center',
                                vertical: 'center',
                                wrapText: true
                            },
                            font: {
                                name: "Calibri",
                                size: 10,
                                bold: true
                            },
                            border: {
                                top: {
                                    style: "medium",
                                    color: "black",
                                },
                                left: {
                                    style: "medium",
                                    color: "black"
                                },
                                right: {
                                    style: "medium",
                                    color: "black"
                                }
                            }
                        }
                    },
                    {
                        string: "0 - VGE Lines",
                        style: {
                            alignment: {
                                horizontal: 'center',
                                vertical: 'center',
                                wrapText: true
                            },
                            font: {
                                name: "Calibri",
                                size: 10,
                                bold: true
                            },
                            border: {
                                left: {
                                    style: "medium",
                                    color: "black"
                                },
                                right: {
                                    style: "medium",
                                    color: "black"
                                }
                            }
                        }
                    },
                    {
                        string: "1 - VoIP Lines",
                        style: {
                            alignment: {
                                horizontal: 'center',
                                vertical: 'center',
                                wrapText: true
                            },
                            font: {
                                name: "Calibri",
                                size: 10,
                                bold: true
                            },
                            border: {
                                bottom: {
                                    style: "medium",
                                    color: "black",
                                },
                                left: {
                                    style: "medium",
                                    color: "black"
                                },
                                right: {
                                    style: "medium",
                                    color: "black"
                                }
                            }
                        }
                    }
                ],
                rowSpan: 2,
                key: "serviceType",
                style: headerStyle,
            },
            {
                string: "Total Number of Lines",
                rowSpan: 2,
                key: "linesTotal",
                style: headerStyle,
            },
            {
                string: "Number of Residential Lines",
                rowSpan: 2,
                key: "linesResidential",
                style: headerStyle,
            }
        ]
        let data = [];
        setLabel("Getting VoIP customers from Chimera...");
        chimera.CommercialCustomer.getByServiceType('voip', signal)
        .then(async customers => {
            for(const customer of customers) {
                for(const location of customer.locations) {
                    if(location.technical.voipLines === 0 && location.serviceTypes.voip) {
                        banners.addBanner('warning', `${customer.displayName} location "${location.nickname}" has 0 VoIP Lines. They will still be added to the report.`, 'Notice');
                    }
                    data.push({
                        customer: {string: customer.displayName},
                        streetAddress: {string: location.serviceAddress.street1 + (location.serviceAddress.street2 ? `, ${location.serviceAddress.street2}` : '')},
                        city: {string: location.serviceAddress.city},
                        county: {string: location.serviceAddress.county},
                        state: {string: location.serviceAddress.state},
                        zip: {string: location.serviceAddress.zip},
                        tract: {string: "", style: tractCellStyle},
                        serviceType: {number: 1},
                        linesTotal: {number: location.technical.voipLines},
                        linesResidential: {number: 0}
                    })
                }
            }
            try {
                setLabel("Generating .xlsx document...");
                const xlsx = await chimera.callAPI(signal, '/api/xlsx', 'POST', {
                    style: {
                        font: {
                            name: "Calibri",
                            size: 10
                        },
                        border: {
                            top: {
                                style: "thin",
                                color: "black"
                            },
                            bottom: {
                                style: "thin",
                                color: "black"
                            },
                            left: {
                                style: "medium",
                                color: "black"
                            },
                            right: {
                                style: "medium",
                                color: "black"
                            }
                        }
                    },
                    options: {
                        sheetFormat: {
                            defaultRowHeight: 12.75,
                            defaultColWidth: 15.5
                        }
                    },
                    dataSets: [
                        {
                            worksheet: "2",
                            headers: headers,
                            data: data
                        }
                    ]
                }, 'blob');
                setBlob(xlsx);
            }
            catch(err) {
                if(err.name !== "AbortError") {
                    console.error(err);
                    banners.addBanner('danger', 'Failed to generate the .xlsx document. The report cannot be created.', 'Error');
                }
            }
            setLoading(false);
        })
        .catch(err => {
            if(err.name !== "AbortError") {
                console.error(err);
                banners.addBanner('danger', 'Could not get VoIP customers', 'Error');
            }
        })
    }, []);

    const downloadBlob = (event) => {
        event.preventDefault();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = new Date();
        link.download = `FCC 477 Data ${today.toISOString().substring(0,10)}.xlsx`;
        link.click();
    }

    return (
        <>
            <h1>FCC 477 Data</h1>
            {loading ? 
                <LoadingSpinner size={50} label={label}/>
            :
                <>
                {blob ? 
                    <button className="btn btn-success mb-2" onClick={downloadBlob}>
                        <i className="fas fa-download"/>
                        &nbsp;Download Report (.xlsx)
                    </button>
                :null}
                </>
            }
        </>
    )
}

const FCC477 = props => {
    return(
        <BannerLog>
            <FCC477Body />
        </BannerLog>
    )
}

export default FCC477;