import React from 'react';
import Modal from './Modal';


const ModalCancelOnly = ({children, context, onClose, backtrack}) => {
    const choices = [
        {
            btnColor: 'secondary',
            btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Cancel</span>,
            func: (e) => {
                if(e) e.preventDefault();
                if(backtrack) {
                    context.backtrack();
                }
                else {
                    context.setModal(null);
                }
                if(onClose) onClose();
            }
        }
    ]

    return(
        <Modal choices={choices} dismiss={choices[0].func}>
            {children}
        </Modal>
    );
}

export default ModalCancelOnly;