import React, {useContext} from 'react';

import ModalContext from '../ModalContext';
import ExportModal from '../ExportModal';
import VendorFormModal from './VendorFormModal';
import AlertsContext from '../AlertsContext';
import ObjectTable from '../ObjectTable';

/**
 * 
 * @param {Array} props.vendors The list of orders to display
 * @param {Function} props.clickedVendor A function that takes the clicked order as an argument
 */
const VendorsTable = props => {
    const modaling = useContext(ModalContext);
    const alerts = useContext(AlertsContext);

    const openNewVendor = (_) => {
        modaling.setModal(<VendorFormModal modalContext={modaling} onClose={() => {props.setVendors(null); alerts.reload();}}/>)
    }

    /** TODO: Implement exporting for Vendors */
    const openExportModal = (selectedObjects) => {
        modaling.setModal(<ExportModal objects={selectedObjects} model="vendor"/>);
    }

    return (
        <ObjectTable 
            id="vendorsTable"
            cols={[
                {
                    label: 'Name', 
                    sort: (a, b) => a.name < b.name ? -1 : 1,
                    render: (obj) => obj.name
                },
                {
                    label: 'Website',
                    sort: (a, b) => a.website < b.website ? -1 : 1,
                    render: (obj) => obj.website
                },
                {
                    label: 'Account #',
                    sort: (a, b) => a.accountNumber < b.accountNumber ? -1 : 1,
                    render: (obj) => obj.accountNumber
                },
            ]}
            objects={props.vendors}
            actions={[
                {label: 'Export', func: openExportModal}
            ]}
            btns={[
                {label: 'Add Vendor', func: openNewVendor}
            ]}
            onClicked={props.clickedVendor}
            search
            paginated
            defaultSortByColName="Name"
            defaultSortAscending={true}
        />
    )
}

export default VendorsTable