import React, {useContext} from 'react';

import ModalContext from '../ModalContext';
import ExportModal from '../ExportModal';
import ButtonOptionList from '../ButtonOptionList';
import ModalCancelOnly from '../ModalCancelOnly';
import VoipOrderFormModal from './VoipOrderFormModal';
import AlertsContext from '../AlertsContext';
import Tooltip from '../Tooltip';
import ObjectTable from '../ObjectTable';
import ConvertTypeModal from './ConvertTypeModal';

/**
 * 
 * @param {Array} props.orders The list of orders to display
 * @param {Function} props.clickedOrder A function that takes the clicked order as an argument
 */
const VoipOrdersTable = props => {
    const modaling = useContext(ModalContext);
    const alerts = useContext(AlertsContext);

    /** @param type An enum string associated with the `type` enum in the VoipOrder schema */
    const openNewOrderForm = (type) => {
        modaling.setModal(null);
        modaling.setModal(<VoipOrderFormModal type={type} modalContext={modaling} onClose={() => {props.setOrders(null); alerts.reload();}}/>)
    }

    const openNewOrderOptions = (_) => {
        const modal = <ModalCancelOnly context={modaling}>
            <ButtonOptionList options={[
                {
                    text: "New Customer",
                    icon: "fas fa-plus",
                    func: (e) => {
                        e.preventDefault();
                        openNewOrderForm('New Customer');
                    }
                }
            ]}/>
            <hr/>
            <div className="row mb-3 mt-3">
                <h4>
                    For existing customers:
                </h4>
            </div>
            <ButtonOptionList options={[
                {
                    text: "Modify",
                    icon: "fas fa-screwdriver-wrench",
                    func: (e) => {
                        e.preventDefault();
                        openNewOrderForm('Modify Service');
                    }
                },
                {
                    text: "Disconnect",
                    icon: "fas fa-minus",
                    func: (e) => {
                        e.preventDefault();
                        openNewOrderForm('Disconnect');
                    }
                }
            ]}/>
        </ModalCancelOnly>
        modaling.setModal(modal);
    }

    /** TODO: Implement exporting for VoipOrders */
    const openExportModal = (selectedObjects) => {
        modaling.setModal(<ExportModal objects={selectedObjects} model="voipOrder"/>);
    }

    const openConvertModal = (selectedObjects) => {
        const callback = () => {
            props.setOrders(null);
            modaling.setModal(null);
        }
        modaling.setModal(<ConvertTypeModal orderClass="voip" orders={selectedObjects} modalContext={modaling} bannerContext={props.parentBanners} callback={callback}/>);
    }

    const statusColor = (status) => {
        switch(status) {
            case "New":
                return "info";
            case "In-Progress":
            case "Waiting on Customer":
                return "warning";
            case "Ready to Bill":
                return "primary";
            case "Completed":
                return "secondary"; //"success";
            case "Delay":
            case "Cancelled":
                return "danger";
            default:
                return "light";
        }
    }

    const typeColor = (type) => {
        switch(type) {
            case "New Customer":
                return "info";
            case "Modify Service":
                return "warning";
            case "Add Location":
                return "primary";
            case "Disconnect":
                return "danger";
            default:
                return "light";
        }
    }

    const abbreviateType = (type) => {
        switch(type) {
            case "New Customer":
                return "New";
            case "Modify Service":
                return "Mod.";
            case "Add Location":
                return "Order";
            case "Disconnect":
                return "Disco.";
            default:
                return "(None)";
        }
    }

    const renderFlag = (object) => {
        const reason = alerts.orderReason(object._id, 'voip');
        if(!reason) return null;
        else return <>&nbsp;<Tooltip text={reason}><i className="fas fa-flag text-danger"/></Tooltip></>
    }

    return (
        <ObjectTable 
            id="voipOrdersTable"
            cols={[
                {
                    label: 'SO #', 
                    sort: (a, b) => a.number < b.number ? -1 : 1,
                    render: (obj) => <>{obj.number}{renderFlag(obj)}</>
                },
                {
                    label: 'Type', 
                    sort: (a, b) => {
                        const values = {
                            'New Customer': 0,
                            'Add Location': 1,
                            'Modify Service': 2,
                            'Disconnect': 3,
                        }
                        return values[`${a.status}`] < values[`${b.status}`] ? -1 : 1;
                    },
                    render: (obj) => abbreviateType(obj.type),
                    color: (obj) => typeColor(obj.type)
                },
                {
                    label: 'Status',
                    sort: (a, b) => {
                        const values = {
                            'New': 0,
                            'In-Progress': 1,
                            'Ready to Bill': 2,
                            'Delay': 3,
                            'Waiting on Customer': 4,
                            'Completed': 5,
                            'Cancelled': 6
                        }
                        return values[`${a.status}`] < values[`${b.status}`] ? -1 : 1;
                    },
                    render: (obj) => obj.status,
                    color: (obj) => statusColor(obj.status)
                },
                {
                    label: 'Customer Name',
                    sort: (a, b) => a.customerName < b.customerName ? -1 : 1,
                    render: (obj) => obj.customerName
                },
                {
                    label: 'Onboarding Person',
                    sort: (a, b) => a.onboardingPerson.email < b.onboardingPerson.email ? -1 : 1,
                    render: (obj) => obj.onboardingPerson.email ? `${obj.onboardingPerson.first.substring(0,1)}${obj.onboardingPerson.last.substring(0,1)}` : '(None)'
                },
                {
                    label: 'Salesperson',
                    sort: (a, b) => a.salesperson < b.salesperson ? -1 : 1,
                    render: (obj) => obj.salesperson ? obj.salesperson : '(None)'
                },
                {
                    label: 'Last Updated',
                    sort: (a, b) => a.updatedAt < b.updatedAt ? -1 : 1,
                    render: (obj) => new Date(obj.updatedAt).toLocaleString()
                },
                {
                    label: 'FOC Date',
                    sort: (a, b) => a.portStatuses.phoneNumber.focDate < b.portStatuses.phoneNumber.focDate ? -1 : 1,
                    render: (obj) => obj.portStatuses.phoneNumber.focDate ? new Date(`${obj.portStatuses.phoneNumber.focDate}T13:00:00`).toLocaleDateString() : '(None)'
                }
            ]}
            objects={props.orders}
            actions={[
                {label: 'Export', func: openExportModal},
                {label: 'Convert Type...', func: openConvertModal}
            ]}
            filters={[
                {label: 'Default', value: 'Default', func: (order) => order.status !== "Completed" && order.status !== "Cancelled"},
                {label: 'Show All', value: 'All', func: (_) => true},
                {label: 'New', value: 'New', func: (order) => order.status === "New"},
                {label: 'In-Progress', value: 'In-Progress', func: (order) => order.status === "In-Progress"},
                {label: 'Ready to Bill', value: 'Ready to Bill', func: (order) => order.status === "Ready to Bill"},
                {label: 'Completed', value: 'Completed', func: (order) => order.status === "Completed"},
                {label: 'Cancelled', value: 'Cancelled', func: (order) => order.status === "Cancelled"},
            ]}
            btns={[
                {label: 'VoIP Order', func: openNewOrderOptions}
            ]}
            onClicked={props.clickedOrder}
            search
            paginated
            defaultSortByColName="Created Date"
            defaultSortAscending={false}
        />
    )
}

export default VoipOrdersTable