const Checklist = ({name, items, setItems, fullWidth, disabled, isEditing}) => {

    const addItem = (e) => {
        e.preventDefault();
        setItems([...items, {checked: false, text: ''}]);
    }

    const updateItemAtIndex = (index, newItem) => {
        const newItems = JSON.parse(JSON.stringify(items));
        newItems[index] = newItem;
        setItems(newItems);
    }

    const removeItemAtIndex = (index) => {
        setItems(items.filter((_, i) => i !== index));
    }

    return (
        <div>
            {items.map((item, i) => <div key={i} className="form-check">
                <input className="form-check-input" type="checkbox" checked={item.checked} id={`${name}_${i}`} disabled={disabled || !isEditing} onChange={(e) => {updateItemAtIndex(i, {checked: e.target.checked, text: item.text})}}/>
                <label className={fullWidth ? "form-check-label w-100" : "form-check-label"} htmlFor={`${name}_${i}`}>
                    {isEditing ? 
                        <div className="d-flex">
                            <input type="text" className="form-control" value={item.text} onChange={(e) => {e.preventDefault(); updateItemAtIndex(i, {checked: item.checked, text: e.target.value})}}/>
                            <button className="btn btn-sm btn-danger ms-1" onClick={(e) => {e.preventDefault(); removeItemAtIndex(i)}} disabled={disabled}>
                                <i className="fas fa-times"/>
                            </button>
                        </div>
                    : <>{item.text}</>}
                </label>
            </div>)}
            {isEditing ?
            <button className="btn btn-sm btn-success" onClick={addItem} disabled={disabled}>
                <i className="fas fa-plus"/>
            </button>
            :null}
        </div>
    )
}

export default Checklist;