import React, { useEffect, useRef } from 'react';

/**
 * props.choices is an array of objects with schema:
 * {
 *     btnColor: String, // a color for a Bootstrap color (primary, secondary, success, etc.)
 *     btnInner: JSX or String, // What is used as the label for the buttons, JSX is supported so icons and etc. can be included
 *     func: (event) => {...} // A function that handles the onClick event.
 * }
 */

function Modal(props) {
    const firstFocusableItem = useRef(null);
    const lastFocusableItem = useRef(null);

    useEffect(() => {
        if(firstFocusableItem.current) {
            firstFocusableItem.current.focus();
        }
    }, [firstFocusableItem]);

    const clampTabbing = (e) => {
        const key = e.key;
        if(key === "Escape") {
            props.dismiss();
            return;
        }
        else if(key === "Tab" && e.shiftKey && firstFocusableItem.current && firstFocusableItem.current.contains(e.target)) {
            lastFocusableItem.current.focus();
            e.preventDefault();
            return;
        }
        else if(key === "Tab" && !e.shiftKey && lastFocusableItem.current && lastFocusableItem.current.contains(e.target)) {
            firstFocusableItem.current.focus();
            e.preventDefault();
            return;
        }
    }

    return(
        <div className="mymodal-backdrop">
            <div className="mymodal" onKeyDown={clampTabbing}>
                <button className="mymodal-close" onClick={props.dismiss} ref={firstFocusableItem}>
                    x
                </button>
                <div>
                    {props.children}
                </div>
                <div className="row mt-1">
                    {props.choices ? props.choices.map((choice, i) => 
                        <div className="col" key={i}>
                            <button className={`btn btn-${choice.btnColor}${choice.addClasses ? ` ${choice.addClasses}` : ''}`} onClick={choice.func} ref={i === props.choices.length - 1 ? lastFocusableItem : null}>
                                {choice.btnInner}
                            </button>
                        </div>
                    )
                    : null}
                </div>
            </div>
        </div>
    )
}

const choiceCancel = (props, confirmCancel, confirmContents, buttonText, saveSettings) => {
    const cancel = () => {
        if(props.backtrack) {
            props.modalContext.backtrack();
            if(confirmCancel) props.modalContext.backtrack();
        }
        else {
            props.modalContext.setModal(null);
        }
        if(props.onClose) props.onClose();
    }

    return {
        btnColor: 'secondary',
        btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;{buttonText ? buttonText : "Cancel"}</span>,
        func: (e) => {
            e.preventDefault();
            if(confirmCancel) {
                // Render a modal that confirms the cancellation.
                const choices = [
                    {
                        btnColor: 'secondary',
                        btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Don't Close</span>,
                        func: (e) => {
                            e.preventDefault();
                            props.modalContext.backtrack();
                        }
                    },
                    {
                        btnColor: 'danger',
                        btnInner: <span><i className="fas fa-times"/>&nbsp;Close Without Saving</span>,
                        func: (e) => {
                            e.preventDefault();
                            cancel();
                        }
                    }
                ]
                if(saveSettings) {
                    choices.push({
                        btnColor: 'primary',
                        btnInner: <span><i className="fas fa-floppy-disk"/>&nbsp;Save & Close</span>,
                        func: (e) => {
                            e.preventDefault();
                            saveSettings.func(saveSettings.resolve, saveSettings.reject);
                        }
                    })
                }
                const confirmModal = <Modal choices={choices} dismiss={choices[0].func}>
                    {confirmContents ? confirmContents : null}
                </Modal>
                props.modalContext.setModal(confirmModal);
            }
            else {
                cancel();
            }
        }
    }
}

/**
 * 
 * @param {Function} func The function to be called when the button is pressed.
 * @param {String} opts.icon An overwrite for the default icon.
 * @param {String} opts.label An overwrite for the default label.
 */
const choiceDelete = (props, func, opts) => {
    if(!opts) {
        opts = {};
    }
    return {
        btnColor: 'danger',
        btnInner: <span><i className={opts.icon ? opts.icon : "fas fa-times"}/>&nbsp;{opts.label ? opts.label : "Delete"}</span>,
        func: (e) => {
            e.preventDefault();
            if(!opts.noConfirm) {
                const choices = [
                    {
                        btnColor: 'secondary',
                        btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Cancel</span>,
                        func: (e) => {
                            e.preventDefault();
                            props.modalContext.backtrack();
                        }
                    },
                    {
                        btnColor: 'danger',
                        btnInner: <span><i className="fas fa-times"/>&nbsp;Delete Permanently</span>,
                        func: (e) => {
                            e.preventDefault();
                            func();
                        }
                    }
                ]
                const confirmModal = <Modal choices={choices} dismiss={choices[0].func}>
                    <h3>Are you sure?</h3>
                    <p>Are you sure you want to perform this action? <strong>This deletion cannot be undone.</strong></p>
                </Modal>
                props.modalContext.setModal(confirmModal);
            }
            else {
                func();
            }
        }
    }
}

export { Modal as default, choiceCancel, choiceDelete };