import React, {useState, useEffect, useContext} from 'react';

import chimera from '../../chimera';
import SmartForm from '../SmartForm';
import UserContext from '../../UserContext';
import BannerContext from '../BannerLogContext';
import ModalContext from '../ModalContext';
import TextPromptModal from '../TextPromptModal';

const DEFAULT_PROJECT = {
    title: '',
    customer: {
        name: '',
        ref: '',
        qbId: ''
    },
    assignee: {
        first: '',
        last: '',
        email: ''
    },
    status: 'New',
    timeline: 'TBD',
    qbInvoice: {
        number: '',
        id: '',
        status: 'NOT SET'
    },
    qbEstimate: {
        number: '',
        id: ''
    },
    price: '',
    tasks: [],
    notes: [],
    attachments: []
}

const ProjectForm = ({project, parentSetModified, parentSetProject, parentSetSavedProject}) => {
    const [workingProject, setWorkingProject] = useState(project ? project : DEFAULT_PROJECT);
    const [modified, setModified] = useState(false);
    const userContext = useContext(UserContext);
    const modaling = useContext(ModalContext);
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(parentSetModified) {
            parentSetModified(modified);
        }
    }, [modified]);

    useEffect(() => {
        if(parentSetProject) {
            parentSetProject(workingProject);
        }
    }, [workingProject]);

    const handleChange = (e) => {
        if(e.target.type !== "checkbox") e.preventDefault();
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const type = e.target.type;
        let newWorkingProject = JSON.parse(JSON.stringify(workingProject));
        if(type === 'number') {
            chimera.setAttr(newWorkingProject, name, parseFloat(value));
        }
        else if(name.split('.')[name.split('.').length-1].toLowerCase().includes("phone")) {
            chimera.setAttr(newWorkingProject, name, value.replace(/\D/g, ''));
        }
        else {
            chimera.setAttr(newWorkingProject, name, value);
        }
        setModified(true);
        setWorkingProject(newWorkingProject);
    }

    const handleSave = (objToSave) => {
        return new Promise((resolve, reject) => {
            if(!objToSave) objToSave = workingProject;
            const isPost = objToSave._id ? false : true;
            chimera.callAPI(undefined, `/api/projects${!isPost ? `/${objToSave._id}` : ''}`, isPost ? 'POST' : 'PUT', objToSave)
            .then(savedProject => {
                if(parentSetSavedProject) {
                    parentSetSavedProject(savedProject);
                }
                resolve(savedProject);
            })
            .catch(err => reject(err));
        })
    }

    const hasBillingWritePerms = () => {
        const perms = userContext.permissions;
        return perms.managerial.write || perms.financial.write || perms.admin.write;
    }

    const projectToQbTxn = (description) => {
        return {
            CustomerRef: {
                value: workingProject.customer.qbId
            },
            Line: [
                {
                    Description: description,
                    DetailType: 'SalesItemLineDetail',
                    SalesItemLineDetail: {
                        Qty: 1,
                        UnitPrice: chimera.dollarStrToNum(workingProject.price),
                        ItemRef: {
                            name: 'ITSA',
                            value: '567'
                        }
                    },
                    Amount: chimera.dollarStrToNum(workingProject.price)
                }
            ]
        }
    }

    const handleGenerateEstimate = () => {
        return new Promise((resolve, reject) => {
            const textPromptCallback = (text) => {
                modaling.backtrack();
                
                chimera.callQuickBooksAPI(undefined, '/api/qb/estimate', 'POST', projectToQbTxn(text))
                .then(estimate => {
                    const newWorkingProject = JSON.parse(JSON.stringify(workingProject));
                    newWorkingProject.qbEstimate = {
                        number: estimate.DocNumber,
                        id: estimate.Id
                    }
                    setWorkingProject(newWorkingProject);
                    setModified(true);
                    if(banners) {
                        banners.addBanner('success', 'QB Estimate successfully created. Be sure to save!', 'QB Estimate');
                    }
                    resolve();
                })
                .catch(err => {
                    console.error(err);
                    if(banners) {
                        banners.addBanner('danger', 'Failed to create Estimate in QuickBooks', 'QuickBooks Error');
                    }
                    else {
                        alert(`QuickBooks Error: Failed to create Estimate in QuickBooks`);
                    }
                    reject(err);
                })
            }

            modaling.setModal(<TextPromptModal 
                label="Line Item Description"
                description="This will be the Description used on the ITSA line item for the Estimate in QuickBooks."
                defaultValue={workingProject.title}
                callback={textPromptCallback}
                onClose={() => {resolve();}}
            />)
        })
    }

    const handleGenerateInvoice = () => {
        return new Promise((resolve, reject) => {
            const generate = (body, estimateToClose) => {
                chimera.callQuickBooksAPI(undefined, '/api/qb/newinvoice', 'POST', body)
                .then(invoice => {
                    const newWorkingProject = JSON.parse(JSON.stringify(workingProject));
                    newWorkingProject.qbInvoice = {
                        number: invoice.DocNumber,
                        id: invoice.Id,
                        status: invoice.Balance === 0 ? 'Paid' : 'Unpaid'
                    }
                    setWorkingProject(newWorkingProject);
                    setModified(true);
                    if(banners) {
                        banners.addBanner('success', 'QB Invoice successfully created. Be sure to save!', 'QB Invoice');
                    }
                    
                    if(estimateToClose) {
                        const newEstimate = JSON.parse(JSON.stringify(estimateToClose));
                        newEstimate.TxnStatus = 'Closed';
                        chimera.callQuickBooksAPI(undefined, '/api/qb/estimate', 'PUT', newEstimate)
                        .then(_ => {
                            if(banners) {
                                banners.addBanner('info', 'QB Estimate was successfully closed.', 'QB Estimate');
                            }
                            resolve();
                        })
                        .catch(err => {
                            console.error(err);
                            if(banners) {
                                banners.addBanner('danger', 'Failed to close the Estimate. Please close it manually.', 'QuickBooks Error');
                            }
                            else {
                                alert('QuickBooks Error: Failed to close the Estimate. Please close it manually.');
                            }
                            reject(err);
                        })
                    }
                    else {
                        resolve();
                    }
                })
                .catch(err => {
                    console.error(err);
                    if(banners) {
                        banners.addBanner('danger', 'Failed to create Invoice in QuickBooks', 'QuickBooks Error');
                    }
                    else {
                        alert(`QuickBooks Error: Failed to create Invoice in QuickBooks`);
                    }
                    reject(err);
                })
            }

            if(workingProject.qbEstimate.number !== '') {
                // generate based on the estimate
                chimera.callQuickBooksAPI(undefined, `/api/qb/estimate/${workingProject.qbEstimate.number}`)
                .then(estimate => {
                    let body = {
                        CustomerRef: estimate.CustomerRef,
                        Line: estimate.Line,
                        CustomField: estimate.CustomField
                    }
                    generate(body, estimate);
                })
                .catch(err => {
                    console.error(err);
                    if(banners) {
                        banners.addBanner('danger', 'Failed to read Estimate; cannot create Invoice based on the Estimate.', 'QuickBooks Error');
                    }
                    else {
                        alert('QuickBooks Error: Failed to read Estimate; cannot create Invoice based on the Estimate.');
                    }
                    reject(err);
                })
            }
            else {
                // generate based on the Project, prompting for the Description
                const textPromptCallback = (text) => {
                    modaling.backtrack();
                    generate(projectToQbTxn(text));
                }
    
                modaling.setModal(<TextPromptModal 
                    label="Line Item Description"
                    description="This will be the Description used on the ITSA line item for the Invoice in QuickBooks."
                    defaultValue={workingProject.title}
                    callback={textPromptCallback}
                    onClose={() => {resolve();}}
                />)
            }
        })
    }

    return (
        <SmartForm formId="projectForm" noun="Project" workingObject={workingProject} setWorkingObject={setWorkingProject} modified={modified} setModified={setModified} handleChange={handleChange} handleSave={handleSave}>
            <SmartForm.Main>
                <h3>{workingProject.number ? `Project #${workingProject.number}` : 'New Project'}</h3>
                <SmartForm.Section nCols={4}>
                    <SmartForm.StringField path="title" label="Project Title"/>
                    <SmartForm.CustomerSelector path="customer"/>
                    <SmartForm.AssigneeSelector path="assignee"/>
                    <SmartForm.SelectField path="timeline" label="Timeline" excludeNoneSelected fullWidth options={[
                        {label: "TBD", value: "TBD"},
                        {label: "<1 week", value: "<1 week"},
                        {label: "1-2 weeks", value: "1-2 weeks"},
                        {label: "2+ weeks", value: "2+ weeks"},
                    ]}/>
                </SmartForm.Section>
                <SmartForm.Divider/>
                {hasBillingWritePerms() ? <>
                <h4>Billing</h4>
                <SmartForm.Section nCols={4}>
                    <SmartForm.QBEstimateField path="qbEstimate" label="QB Estimate #" fullWidth/>
                    <SmartForm.QBInvoiceField path="qbInvoice" label="QB Invoice #" fullWidth/>
                    <SmartForm.DollarField path="price" label="Price" fullWidth/>
                </SmartForm.Section>
                <SmartForm.Divider/>
                </>:null}
                <h4>Tasks</h4>
                <SmartForm.Section nCols={1}>
                    <SmartForm.ChecklistField path="tasks" label="Tasks" fullWidth/>
                </SmartForm.Section>
                <SmartForm.Divider/>
                <SmartForm.AttachmentsSection path="attachments"/>
            </SmartForm.Main>
            <SmartForm.Sidebar>
                <SmartForm.SelectField path="status" label="Status" fullWidth excludeNoneSelected options={[
                    {label: "New", value: "New"},
                    {label: "In-Progress", value: "In-Progress"},
                    {label: "Scheduled", value: "Scheduled"},
                    {label: "Waiting on Parts", value: "Waiting on Parts"},
                    {label: "Waiting on Customer", value: "Waiting on Customer"},
                    {label: "Ready to Bill", value: "Ready to Bill"},
                    {label: "Billed", value: "Billed"},
                ]}/>
                <SmartForm.SaveButton fullWidth/>
                {hasBillingWritePerms() ? <>
                    <SmartForm.ActionButton label="Generate QB Estimate" pendingLabel="Generating..." icon="fas fa-plus" color="success" action={handleGenerateEstimate} disabled={workingProject.customer.qbId === '' || workingProject.qbInvoice.number !== ''}    fullWidth/>
                    <SmartForm.ActionButton label="Generate QB Invoice"  pendingLabel="Generating..." icon="fas fa-plus" color="success" action={handleGenerateInvoice}  disabled={workingProject.customer.qbId === '' || workingProject.status !== 'Ready to Bill'} fullWidth/>
                </> : null}
                <SmartForm.Notes path="notes"/>
            </SmartForm.Sidebar>
        </SmartForm>
    )
}

export default ProjectForm;