import React, {useState, useEffect, useContext} from 'react';

import chimera from '../../chimera';
import ModalContext from '../ModalContext';
import ExportModal from '../ExportModal';
import ButtonOptionList from '../ButtonOptionList';
import InternetOrderFormModal from './InternetOrderFormModal';
import ModalCancelOnly from '../ModalCancelOnly';
import BannerContext from '../BannerLogContext';
import AlertsContext from "../AlertsContext";
import SnoozeModal from './SnoozeModal';
import DelayModal from './DelayModal';
import Tooltip from '../Tooltip';
import ObjectTable from '../ObjectTable';
import ConvertTypeModal from './ConvertTypeModal';
import LoadingSpinner from '../LoadingSpinner';

/**
 * 
 * @param {String} props.orderId the ID of the associated Order (used for the PUT request)
 * @param {String} props.status the currently selected Retail/Wholesale status of the Order
 * @param {Boolean} props.isRetail if true, show Retail status options. if false, show Wholesale status options.
 */
const InlineStatusSelector = props => {
    const [isSaving, setIsSaving] = useState(false);
    const [status, setStatus] = useState(props.status);
    const banners = useContext(BannerContext);
    const alertsContext = useContext(AlertsContext);
    
    const handleChange = (event) => {
        event.preventDefault();
        const value = event.target.value;
        setStatus(value);
        setIsSaving(true);
        let body = props.isRetail ? {retailStatus: value} : {wholesaleStatus: value};
        if(props.isRetail) {
            if(props.currentStatus !== "Completed" && props.currentStatus !== "Cancelled" && props.currentStatus !== "Delayed") {
                if(value === "SO Signed") {
                    body.status = "In-Progress";
                }
                else if(value === "Service Info Sent") {
                    body.status = "Provisioned";
                }
                else {
                    body.status = "New";
                }
            }
            props.setRetailStatusCallback(props.orderId, value);
        }
        chimera.callAPI(undefined, `/api/internetorders/${props.orderId}`, 'PUT', body)
        .then(_ => {
            setIsSaving(false);
            alertsContext.reload();
        })
        .catch(err => {
            console.error(err);
            banners.addBanner('danger', `Failed to save ${props.isRetail ? 'Retail' : 'Wholesale'} Status`, 'Error');
        })
    }

    return (
        <select className="form-select" onChange={handleChange} disabled={isSaving} value={status} id={`orderId_${props.orderId}_status`}>
            {props.isRetail ? 
                <>
                <option value="NOT SET">--NOT SET--</option>
                <option value="Waiting on Customer">Waiting on Customer</option>
                <option value="Quote Sent">Quote Sent</option>
                <option value="Quote Accepted">Quote Accepted</option>
                <option value="SO Sent">SO Sent</option>
                <option value="SO Signed">SO Signed</option>
                <option value="Service Info Sent">Service Info Sent</option>
                </>
            :
                <>
                <option value="NOT SET">--NOT SET--</option>
                <option value="No Contract">No Contract</option>
                <option value="Quote Requested">Quote Requested</option>
                <option value="Quote Received">Quote Received</option>
                <option value="SO Requested">SO Requested</option>
                <option value="SO Received">SO Received</option>
                <option value="SO Signed">SO Signed</option>
                <option value="SOA Received">SOA Received</option>
                <option value="N/A">N/A</option>
                </>
            }
        </select>
    )
}

/**
 * 
 * @param {Array} props.orders The list of orders to display
 * @param {Function} props.clickedOrder A function that takes the clicked order as an argument
 */
const InternetOrdersTable = props => {
    const modaling = useContext(ModalContext);
    const alertsContext = useContext(AlertsContext);
    const banners = useContext(BannerContext);
    const [displayStatusesById, setDisplayStatusesById] = useState(null);

    useEffect(() => {
        if(displayStatusesById === null && props.orders !== null) {
            let newDisplayStatusesById = {};
            for(const order of props.orders) {
                newDisplayStatusesById[order._id] = order.status
            }
            setDisplayStatusesById(newDisplayStatusesById);
        }
    }, [displayStatusesById, props.orders]);

    const setDisplayStatusForId = (id, newStatus) => {
        let newDisplayStatusesById = JSON.parse(JSON.stringify(displayStatusesById));
        newDisplayStatusesById[id] = newStatus;
        setDisplayStatusesById(newDisplayStatusesById);
    }

    const setRetailStatusCallback = (id, newRetailStatus) => {
        // Protected statuses are unaffected by the Retail Status changing
        const displayStatus = displayStatusesById[id];
        if(displayStatus !== "Completed" && displayStatus !== "Cancelled" && displayStatus !== "Delayed") {
            if(newRetailStatus === "SO Signed" || newRetailStatus === "Waiting on Customer") {
                setDisplayStatusForId(id, 'In-Progress');
            }
            else if(newRetailStatus === "Service Info Sent") {
                setDisplayStatusForId(id, 'Provisioned');
            }
            else if(newRetailStatus !== "Waiting on Customer") {
                setDisplayStatusForId(id, 'New');
            }
        }
    }

    /** @param type An enum string associated with the `type` enum in the InternetOrder schema */
    const openNewOrderForm = (type) => {
        modaling.setModal(null);
        modaling.setModal(<InternetOrderFormModal type={type} modalContext={modaling} onClose={() => {props.setOrders(null); alertsContext.reload()}}/>)
    }

    const openNewOrderOptions = (_) => {
        const modal = <ModalCancelOnly context={modaling}>
            <ButtonOptionList options={[
                {
                    text: "New Customer",
                    icon: "fas fa-plus",
                    func: (e) => {
                        e.preventDefault();
                        openNewOrderForm('New Customer');
                    }
                }
            ]}/>
            <hr/>
            <div className="row mb-3 mt-3">
                <h4>
                    For existing customers:
                </h4>
            </div>
            <ButtonOptionList options={[
                {
                    text: "Modify",
                    icon: "fas fa-screwdriver-wrench",
                    func: (e) => {
                        e.preventDefault();
                        openNewOrderForm('Modify Service');
                    }
                },
                {
                    text: "Disconnect",
                    icon: "fas fa-minus",
                    func: (e) => {
                        e.preventDefault();
                        openNewOrderForm('Disconnect');
                    }
                }
            ]}/>
        </ModalCancelOnly>
        modaling.setModal(modal);
    }

    /** TODO: Implement exporting for InternetOrders */
    const openExportModal = (selectedObjects) => {
        modaling.setModal(<ExportModal objects={selectedObjects} model="internetOrder"/>);
    }

    const openSnoozeModal = (selectedObjects) => {
        modaling.setModal(<SnoozeModal orders={selectedObjects} modalContext={modaling} bannerContext={banners}/>);
    }

    const openDelayModal = (selectedObjects) => {
        const callback = () => {
            props.setOrders(null);
            modaling.setModal(null);
        }
        modaling.setModal(<DelayModal orders={selectedObjects} bannerContext={banners} callback={callback}/>);
    }

    const openConvertModal = (selectedObjects) => {
        const callback = () => {
            props.setOrders(null);
            modaling.setModal(null);
        }
        modaling.setModal(<ConvertTypeModal orderClass="internet" orders={selectedObjects} modalContext={modaling} bannerContext={banners} callback={callback}/>);
    }

    const statusColor = (status) => {
        switch(status) {
            case "New":
                return "info";
            case "In-Progress":
                return "warning";
            case "Provisioned":
                return "primary";
            case "Completed":
                return "secondary"; //"success";
            case "Cancelled":
                return "danger";
            case "Delayed":
                return "dark"; //"secondary";
            default:
                return "light";
        }
    }

    const typeColor = (type) => {
        switch(type) {
            case "New Customer":
                return "info";
            case "Modify Service":
                return "warning";
            case "Add Location":
                return "primary";
            case "Disconnect":
                return "danger";
            default:
                return "light";
        }
    }

    const abbreviateType = (type) => {
        switch(type) {
            case "New Customer":
                return "New";
            case "Modify Service":
                return "Mod.";
            case "Add Location":
                return "Order";
            case "Disconnect":
                return "Disco.";
            default:
                return "(None)";
        }
    }

    const renderFlag = (obj) => {
        const reason = alertsContext.orderReason(obj._id, 'internet');
        if(!reason) return null;
        else return <>&nbsp;<Tooltip text={reason}><i className="fas fa-flag text-danger"/></Tooltip></>
    }

    return (
        <>
        {displayStatusesById ? 
        <ObjectTable 
            id="internetOrdersTable"
            cols={[
                {
                    label: 'SO #',
                    sort: (a, b) => a.number < b.number ? -1 : 1,
                    render: (obj) => <>{obj.number}{renderFlag(obj)}</>
                },
                {
                    label: 'Type', 
                    sort: (a, b) => {
                        const values = {
                            'New Customer': 0,
                            'Add Location': 1,
                            'Modify Service': 2,
                            'Disconnect': 3,
                        }
                        return values[`${a.type}`] < values[`${b.type}`] ? -1 : 1;
                    },
                    render: (obj) => abbreviateType(obj.type),
                    color: (obj) => typeColor(obj.type)
                },
                {
                    label: 'Status',
                    sort: (a, b) => {
                        const values = {
                            'New': 0,
                            'In-Progress': 1,
                            'Provisioned': 2,
                            'Completed': 3,
                            'Delayed': 4,
                            'Cancelled': 5
                        }
                        return values[`${a.status}`] < values[`${b.status}`] ? -1 : 1;
                    },
                    render: (obj) => displayStatusesById[obj._id],
                    color: (obj) => statusColor(displayStatusesById[obj._id])
                },
                {
                    label: 'Customer Name',
                    sort: (a, b) => a.customerName < b.customerName ? -1 : 1,
                    render: (obj) => obj.customerName
                },
                {
                    label: 'Wholesale Status',
                    sort: (a, b) => {
                        const values = {
                            'NOT SET': 0,
                            'No Contract': 1,
                            'Quote Requested': 2,
                            'Quote Received': 3,
                            'SO Requested': 4,
                            'SO Received': 5,
                            'SO Signed': 6,
                            'SOA Received': 7
                        }
                        return values[`${a.wholesaleStatus}`] < values[`${b.wholesaleStatus}`] ? -1 : 1;
                    },
                    noClick: true,
                    render: (obj) => <InlineStatusSelector key={obj._id} orderId={obj._id} status={obj.wholesaleStatus}/>
                },
                {
                    label: 'Retail Status',
                    sort: (a, b) => {
                        const values = {
                            'NOT SET': 0,
                            'Quote Sent': 1,
                            'Quote Accepted': 2,
                            'SO Sent': 3,
                            'SO Signed': 4,
                            'Service Info Sent': 5
                        }
                        return values[`${a.retailStatus}`] < values[`${b.retailStatus}`] ? -1 : 1;
                    },
                    noClick: true,
                    render: (obj) => <InlineStatusSelector key={obj._id} orderId={obj._id} status={obj.retailStatus} isRetail setRetailStatusCallback={setRetailStatusCallback} currentStatus={obj.status}/>
                },
                {
                    label: 'Assignee',
                    sort: (a, b) => a.assignee.email < b.assignee.email ? -1 : 1,
                    render: (obj) => obj.assignee.email ? `${obj.assignee.first.substring(0,1)}${obj.assignee.last.substring(0,1)}` : '(None)'
                },
                {
                    label: 'Last Updated',
                    sort: (a, b) => a.updatedAt < b.updatedAt ? -1 : 1,
                    render: (obj) => new Date(obj.updatedAt).toLocaleString()
                },
                {
                    label: 'FOC Date',
                    sort: (a, b) => a.focDate < b.focDate ? -1 : 1,
                    render: (obj) => obj.focDate ? new Date(`${obj.focDate}T13:00:00`).toLocaleDateString() : '(None)'
                }
            ]}
            objects={props.orders}
            actions={[
                {label: 'Export', func: openExportModal},
                {label: 'Snooze', func: openSnoozeModal},
                {label: 'Delay', func: openDelayModal},
                {label: 'Convert Type...', func: openConvertModal}
            ]}
            filters={[
                {label: 'Default', value: 'Default', func: (order) => order.status !== "Completed" && order.status !== "Cancelled"},
                {label: 'New', value: 'New', func: (order) => order.status === "New"},
                {label: 'In-Progress', value: 'In-Progress', func: (order) => order.status === "In-Progress"},
                {label: 'Provisioned', value: 'Provisioned', func: (order) => order.status === "Provisioned"},
                {label: 'Completed', value: 'Completed', func: (order) => order.status === "Completed"},
                {label: 'Cancelled', value: 'Cancelled', func: (order) => order.status === "Cancelled"},
                {label: 'Delayed', value: 'Delayed', func: (order) => order.status === "Delayed"},
                {label: 'Attention Needed', value: 'Attention', func: (order) => alertsContext.isFlagged(order)},
            ]}
            btns={[
                {label: 'Internet Order', func: openNewOrderOptions}
            ]}
            onClicked={props.clickedOrder}
            search
            paginated
            defaultSortByColName="SO #"
            defaultSortAscending={false}
        />
        :
        <LoadingSpinner size={75}/>
        }
        </>
    )
}

export default InternetOrdersTable