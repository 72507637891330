import React from 'react';

/**
 * @param {String} pos (optional) use `'bottom`' for tooltip to appear on bottom, otherwise it will be on top.
 * @param {any} text tooltip contents (wrapped in span)
 * @returns Rendered tooltip div
 */
const Tooltip = ({pos, text, children}) => {
    return(
        <div className="tooltip-container border-0">
            <span className={pos === "bottom" ? "tooltip-text-bottom" : "tooltip-text"}>
                {text}
            </span>
            {children}
        </div>
    )
}

export default Tooltip;