import React, {useState, useContext} from 'react';
import FormField from './FormField';
import ModalContext from './ModalContext';
import Modal, { choiceCancel } from './Modal';

const TextPrompt = ({callback, label, description, defaultValue, onClose}) => {
    const [text, setText] = useState(defaultValue ? defaultValue : '');
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        event.preventDefault();
        if(event.target.name === "text") {
            setText(event.target.value);
        }
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    return(
        <div>
            <FormField
                name="text"
                type="textarea"
                label={label}
                value={text}
                handleChange={handleChange}
                onBlur={trimOnBlur}
                required
                description={description}
            />
            <button className="btn btn-primary" onClick={(e) => {e.preventDefault(); setLoading(true); callback(text);}} disabled={loading}>
                <i className="fas fa-arrow-right"/>&nbsp;{loading ? 'Submitting...' : 'Submit'}
            </button>
        </div>
    )
}

const TextPromptModal = ({callback, label, description, defaultValue, onClose}) => {
    const modaling = useContext(ModalContext);

    const choices = [
        choiceCancel({modalContext: modaling, backtrack: true, onClose: onClose})
    ];

    return (
        <Modal choices={choices} dismiss={choices[0].func}>
            <TextPrompt callback={callback} label={label} description={description} defaultValue={defaultValue}/>
        </Modal>
    )
}

export default TextPromptModal;