import React, {useState, useEffect, useContext} from "react";
import ToolPage from './ToolPage';

import chimera from '../../chimera';
import BannerLogContext from "../BannerLogContext";
import LoadingSpinner from "../LoadingSpinner";
import FormFieldMicro from "../FormFieldMicro";
import ObjectTable from "../ObjectTable";

const TxnTrackerBody = props => {
    const now = new Date();
    const firstOfTheMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const [startDate, setStartDate] = useState(firstOfTheMonth.toISOString().substring(0,10));
    const [endDate, setEndDate] = useState(now.toISOString().substring(0,10));
    const [trackers, setTrackers] = useState(null);
    const banners = useContext(BannerLogContext);

    useEffect(() => {
        setTrackers(null);
        if(startDate && endDate) {
            chimera.callAPI(undefined, `/api/txntrackers/startDate/${startDate}/endDate/${endDate}`)
            .then(trackers => setTrackers(trackers))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read transaction data', 'Error');
            })
        }
    }, [startDate, endDate]);

    const handleChange = (event) => {
        event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;
        if(name === 'startDate') {
            setStartDate(value);
        }
        else if(name === 'endDate') {
            setEndDate(value);
        }
    }
    
    const clickedTracker = (tracker) => {
        const link = document.createElement('a');
        link.href = `https://app.qbo.intuit.com/app/${tracker.type.toLowerCase()}?txnId=${tracker.qbId}`;
        link.target = '_blank';
        link.rel = "noopener noreferrer";
        link.click();
    }

    /**
     * 
     * <ol className="text-start">
                        {trackers.map((tracker, i) => <li key={i}><a href={`https://app.qbo.intuit.com/app/${tracker.type.toLowerCase()}?txnId=${tracker.qbId}`} target="_blank" rel="noopener noreferrer">{tracker.type} {tracker.number}</a> - Created {(new Date(tracker.qbCreatedAt)).toLocaleString()}</li>)}
                    </ol>
     */

    return (
        <div className="col-lg-12">
            <div className="row">
                <div className="col">
                    <FormFieldMicro
                        type="date"
                        name="startDate"
                        label="Start Date"
                        value={startDate}
                        handleChange={handleChange}
                    />
                </div>
                <div className="col">
                    <FormFieldMicro
                        type="date"
                        name="endDate"
                        label="End Date"
                        value={endDate}
                        handleChange={handleChange}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {trackers === null ? <LoadingSpinner size={75}/> : 
                    <ObjectTable 
                        id="txnTrackersTable"
                        cols={[
                            {
                                label: 'Type', 
                                sort: (a, b) => {
                                    const values = {
                                        'Invoice': 0,
                                        'SalesReceipt': 1,
                                        'Bill': 2,
                                    }
                                    return values[`${a.type}`] < values[`${b.type}`] ? -1 : 1;
                                },
                                render: (obj) => obj.type
                            },
                            {
                                label: 'Doc Number',
                                sort: (a, b) => a.number < b.number ? -1 : 1,
                                render: (obj) => obj.number
                            },
                            {
                                label: 'QB ID',
                                sort: (a, b) => a.qbId < b.qbId ? -1 : 1,
                                render: (obj) => obj.qbId
                            },
                            {
                                label: 'Source',
                                sort: (a, b) => a.source < b.source ? -1 : 1,
                                render: (obj) => obj.source
                            },
                            {
                                label: 'Created Date',
                                sort: (a, b) => a.qbCreatedAt < b.qbCreatedAt ? -1 : 1,
                                render: (obj) => (new Date(obj.qbCreatedAt)).toLocaleString()
                            },
                        ]}
                        objects={trackers}
                        filters={[
                            {label: 'Default', value: 'Default', func: (_) => true},
                            {label: 'From Tax Calc', value: 'From Tax Calc', func: (tracker) => tracker.source.includes('taxcalc')},
                            {label: 'From Syncro Invoice', value: 'From Syncro Invoice', func: (tracker) => tracker.source.includes('syncroinvoice')},
                        ]}
                        onClicked={clickedTracker}
                        search
                        paginated
                        defaultSortByColName="Created Date"
                        defaultSortAscending={false}
                    />
                    }
                </div>
            </div>
        </div>
    );
}

const TxnTracker = props => {
    const toolName = "Transaction Trackers";
    const toolId = "txntrackers";
    return (
        <ToolPage toolId={toolId} toolName={toolName}>
            <ToolPage.Header toolName={toolName}>
                View QuickBooks Transactions generated by Chimera.
            </ToolPage.Header>
            <ToolPage.How>
                <h3>About</h3>
                <p>
                    This page is a read-only view of the records made to track QuickBooks Transactions generated by Chimera.
                    Invoices and Sales Receipts generated by tools such as the Tax Calculator or the Syncro Invoice Tool
                    will be shown here.
                </p>
            </ToolPage.How>
            <ToolPage.Body>
                <TxnTrackerBody />
            </ToolPage.Body>
        </ToolPage>
    );
}

export default TxnTracker;